import _ from 'lodash'
import React, {
  useEffect,
  useState,
} from 'react'
import placeholderImage from '../../assets/images/icon_image_placeholder.png'
import combineClassNames from '../../helpers/combineClassNames'
import { useThemeConfig } from '../../hook/use-theme-config'
import useStyles from './image-style'

const EmptyScreen = ({
  alt,
  className,
  placeholderClassName,
  src,
  onChange = () => {},
  onError = () => {},
  ...others
}) => {
  const [noImage, setNoImage] = useState(_.isEmpty(src))
  const { getConfig } = useThemeConfig()
  const styles = useStyles()

  const placeholder = getConfig('config.placeholderImage', placeholderImage)

  useEffect(() => {
    setNoImage(_.isEmpty(src))
  }, [src])

  useEffect(() => {
    onChange(noImage)
  }, [noImage])
  return (
    <img
      role="presentation"
      alt={alt}
      {...others}
      className={combineClassNames([
        styles.image,
        className,
        noImage && styles.placeholder,
        noImage && placeholderClassName,
      ])}
      src={noImage ? placeholder : src}
      onError={(...args) => {
        setNoImage(true)
        onError(...args)
      }}
    />
  )
}

export default EmptyScreen
