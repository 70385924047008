/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash'
import React, {
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import Search from '../search'
import Link from '../link'
import Alert from '../alert'
import ContentGroup from '../content-group'
import OrderMethod from '../order-method'
import ToolsMenu from './tools-menu'
import combineClassNames from '../../helpers/combineClassNames'
import { useThemeConfig } from '../../hook/use-theme-config'
import TofugearLogo from '../../assets/icons/tofugear-logo.svg'
import MenuIconInline from '../../assets/icons/icon_menu.inline.svg'
import useStyles from './header-style'

import NavBar from '../navbar'
import Button from '../button'

const HeaderView = ({
  onSearch,
  onLanguageChange,
  onLayoutChange,
  isOpenSearch,
  openMenuMobile,
  className,
  onMemberCodeOpen,
  topBarContentGroupProps,
  siteName,
}) => {
  // prepare hook
  const { getConfig } = useThemeConfig()
  const { t, i18n } = useTranslation()
  const headerRef = useRef()
  const theme = useTheme()

  const headerDisplayMode = getConfig('config.headerDisplayMode')

  // local variagne
  const {
    addEventListener,
    removeEventListener,
  } = window || {}

  // style
  const styles = useStyles({ headerDisplayMode })
  const handleResize = () => {
    const headerElem = _.get(headerRef, 'current')
    if (headerElem) {
      const rect = headerElem.getBoundingClientRect() || {}
      onLayoutChange(rect)
    }
  }

  useLayoutEffect(handleResize)

  useEffect(() => {
    addEventListener('resize', _.throttle(handleResize, 50))
    return () => {
      removeEventListener('resize', handleResize)
    }
  }, [])

  const toolsMenuProps = {
    className: styles.boxLogin,
    onLanguageChange,
    onMemberCodeOpen,
    onSearch,
  }

  return (
    <header
      ref={headerRef}
      className={combineClassNames([styles.containerFluid, className])}
    >
      {
        topBarContentGroupProps.code && (
          <div className={styles.topBar}>
            <ContentGroup
              {...topBarContentGroupProps}
              lineStyle={styles.topBarContent}
            />
          </div>
        )
      }
      <div className={styles.container}>
        <Button
          inline
          className={styles.burgerMenu}
          onClick={openMenuMobile}
          iconImage={MenuIconInline}
          iconInline
          iconClassName={styles.menuIcon}
          aria-label="Menu"
        >
          <span className={styles.burgerMenuLabel}>
            {t('ui.header.burgerMenu.label')}
          </span>
        </Button>
        <h1 className={styles.logo}>
          <Link gatsbyLink to="/">
            <picture>
              <source
                srcSet={theme.config.logoImageDesktop || TofugearLogo}
                media={`(min-width: ${theme.mediaQueries.tabletLandscape})`}
              />
              <img
                src={theme.config.logoImageMobile || TofugearLogo}
                alt={siteName}
                className={styles.logoImage}
              />
            </picture>
          </Link>
        </h1>
        <OrderMethod
          className={combineClassNames([styles.orderMethod, styles.orderMethodNearLogo])}
          rowClassName={combineClassNames([styles.orderMethodRow, styles.orderMethodNearLogoRow])}
        />
        <ToolsMenu {...toolsMenuProps} />
      </div>
      <Search locale={i18n.language} isOpenSearch={isOpenSearch} className={styles.searchBar} />
      <NavBar containerStyle={styles.megaMenu} />
      <OrderMethod
        className={combineClassNames([styles.orderMethod, styles.orderMethodBelowLogo])}
        rowClassName={combineClassNames([styles.orderMethodRow, styles.orderMethodBelowLogoRow])}
      />
      <Alert />
    </header>
  )
}

export default HeaderView
