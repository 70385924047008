import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// import CurrencyFormat from '../currency-format'
import { useCart, useOrderMethod } from '../../hook'
import useStyles from './dine-in-banner-style'
import Button from '../button'

import ArrowDownIcon from '../../assets/icons/icon_right_arrow.svg'

const DineInBannerView = () => {
  // prepare hook
  const { t } = useTranslation()
  const {
    orderMethod,
  } = useOrderMethod()
  const {
    totalItems,
    initCart,
  } = useCart()

  const orderMethodCode = useMemo(() => (
    _.get(orderMethod, 'code')
  ), [orderMethod])

  const {
    dineInTableNumber,
    dineInTableSubNumber,
  } = useMemo(() => ({
    dineInTableNumber: _.get(initCart, 'meta.dineInTableNumber', ''),
    dineInTableSubNumber: _.get(initCart, 'meta.dineInTableSubNumber', ''),
  }), [initCart])

  // style
  const styles = useStyles()

  if (orderMethodCode !== 'dineIn') return null

  return (
    <div className={styles.container}>
      <div className={styles.stickyContainer}>
        {
          (!_.isEmpty(dineInTableNumber)
            || !_.isEmpty(dineInTableSubNumber)) && (
            <p className={styles.tableNumber}>
              {t(
                'screens.products.dineInOrderPlacedTable',
                {
                  count: totalItems,
                  dineInTableNumber,
                  dineInTableSubNumber,
                },
              )}
            </p>
          )
        }
        <Button
          text={t('screens.products.dineInOrderPlacedButton', { count: totalItems })}
          navigate="/dine-in-order-placed/"
          iconImage={ArrowDownIcon}
          inline
          className={styles.button}
        />
      </div>
    </div>
  )
}

export default DineInBannerView
