import React from 'react'
import useStyles from './title-detail-style'
import { combineClassNames } from '../../helpers'

const TitleView = ({
  text, className,
}) => {
  const { title } = useStyles()
  return (
    <div className={combineClassNames([title, className])}>{text}</div>
  )
}

export default TitleView
