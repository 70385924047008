/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/require-default-props */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Link from '../link'
import urlBuilder from '../../helpers/urlBuilder'
import useStyle from './list-style'
import iconBuilder from '../../helpers/iconBuilder'
import combineClassNames from '../../helpers/combineClassNames';
import IconSolidArrow from '../../assets/icons/icon_solid_arrow.svg'

const ListView = (props) => {
  const {
    title,
    listItems,
    code,
    url,
    template,
    type,
    titleStyle,
    containerStyle,
    itemIconStyle,
  } = props
  const styles = useStyle({
    ...props,
  })

  return (
    <div className={combineClassNames([styles.container, containerStyle])}>
      {code !== 'ecom_footer_checkout_link'
        && (
          <h5 className={combineClassNames([styles.titleStyle, titleStyle])}>
            {title}
          </h5>
        )}
      <ul className={styles.listItemsStyle}>
        {listItems.map((item) => {
          // eslint-disable-next-line react/no-array-index-key
          const text = _.isString(item) ? item : item.name
          const imageUrl = _.get(item, 'attachment.versions.ecomSmall')
          const imageUrlMedium = _.get(item, 'attachment.versions.ecomMedium')
          const configOptions = _.get(item, 'configOptions')
          const display = _.get(configOptions, 'display')
          const linkUrl = urlBuilder(item, url)
          const ListContent = _.isEmpty(linkUrl)
            ? ({ to, ...p }) => (<React.Fragment {...p} />)
            : Link
          if (code === 'ecom_pdp_list_buttons') {
            return (
              <li className={styles.itemButtonStyle} key={item.id || item}>
                <ListContent to={urlBuilder(item, url)}>
                  {item.name || item}
                  <img src={IconSolidArrow} alt={item.name} />
                </ListContent>
              </li>
            )
          }
          if (code === 'ecom_footer_social_media') {
            return (
              <li
                className={styles.itemIconStyle}
                key={item.id || item}
              >
                <a
                  className={styles.itemLinkIconStyle}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {imageUrl
                    ? (
                      <img src={imageUrl} alt={item.name || ''} />
                    )
                    : (
                      <img src={iconBuilder(item.name)} alt={item.name || ''} />
                    )}
                </a>
              </li>
            )
          }
          if (code === 'ecom_payment_logos' || code === 'ecom_secure_payment_logos') {
            return (
              <li
                className={
                  combineClassNames([
                    styles.itemIconStyle,
                    itemIconStyle,
                  ])
                }
                key={item.id || item}
              >
                {imageUrl
                  ? (
                    <img src={imageUrl} alt={item.name} />
                  )
                  : (
                    <img src={iconBuilder(item.name)} alt={item.name} />
                  )}
              </li>
            )
          }
          return (
            <li
              className={combineClassNames([
                styles.itemLinkStyle,
                template && styles.itemLinkTemplateStyle,
                type === 'tag' && styles.itemLinkTagStyle,
                display === 'inline' && styles.itemLinkInlineStyle,
                !_.isEmpty(imageUrlMedium) && styles.itemLinkImageStyle,
              ])}
              key={item.id || item}
            >
              <ListContent to={urlBuilder(item, url)}>
                {imageUrlMedium
                  ? (
                    <img src={imageUrlMedium} alt={item.name} className={styles.imageIcon} />
                  )
                  : (
                    <>
                      {text}
                      {item.articleCount && ` (${item.articleCount})` }
                    </>
                  )}
              </ListContent>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ListView.propTypes = {
  title: PropTypes.string.isRequired,
  listItems: PropTypes.array,
}

ListView.defaultProps = {
  listItems: [],
}

export default ListView
