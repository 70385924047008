import _ from 'lodash'
import parseThemeStyleUnit from '../../helpers/parse-theme-style-unit'
import ratioCalc from '../../helpers/ratioImage'
import parseAspectRatio from '../../helpers/parse-aspect-ratio'

const uiHeaderToolsMenuDefaultValue = {
  mobile: ['cart'],
  tabletLandscape: ['language', 'account', 'cart', 'search'],
}
const getThemeConfigStylesRegistrationList = (mergedTheme) => ([
  // ## ui ##
  {
    key: 'colors.background',
    styleProperty: '--ui-body-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'colors.innerBackground',
    styleProperty: '--ui-body-inner-background-color',
    defaultValue: 'transparent',
  },
  // -- header --
  {
    key: 'config.ui.header.enableSticky',
    styleProperty: '--ui-header-position',
    getValueFn: (value) => {
      if (!_.isBoolean(value)) return value
      return value ? 'sticky' : 'static'
    },
    defaultValue: {
      mobile: true,
      tabletLandscape: false,
    },
  },
  {
    key: [
      'config.headerBackgroundColor',
      'colors.background',
    ],
    styleProperty: '--ui-header-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.headerBorderWidth',
    styleProperty: '--ui-header-border-bottom-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 1,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerBorderStyle',
    styleProperty: '--ui-header-border-bottom-style',
    defaultValue: 'solid',
  },
  {
    key: 'config.headerBorderColor',
    styleProperty: '--ui-header-border-bottom-color',
    defaultValue: mergedTheme.colors.bgMedium,
  },
  {
    key: 'config.headerTopSpacing',
    styleProperty: '--ui-header-top-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: '3.3rem',
    },
  },
  {
    key: 'config.headerBottomSpacing',
    styleProperty: '--ui-header-bottom-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: '2.7rem',
    },
  },
  // -- header - logo --
  {
    key: 'config.headerLogoWidth',
    getValueFn: parseThemeStyleUnit,
    styleProperty: '--ui-header-logo-width',
    defaultValue: {
      mobile: '10rem',
      tabletLandscape: '20rem',
    },
  },
  {
    key: 'config.headerCheckoutLogoWidth',
    getValueFn: parseThemeStyleUnit,
    styleProperty: '--ui-header-checkout-logo-width',
    defaultValue: 'var(--ui-header-logo-width)',
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-logo-container-justify-content',
    getValueFn: (pos) => {
      switch (pos) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'center';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  // -- header - tools menu --
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-tools-menu-position',
    getValueFn: (pos) => {
      switch (pos) {
        case 'right':
          return 'static';
        default:
          return 'absolute';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerToolsMenuPosition',
    styleProperty: '--ui-header-tools-menu-top',
    getValueFn: (pos) => {
      switch (pos) {
        case 'bottom':
          return 'auto';
        default:
          return 0;
      }
    },
    defaultValue: {
      mobile: 'center',
      tabletLandscape: 'bottom',
    },
  },
  {
    key: 'config.headerToolsMenuPosition',
    styleProperty: '--ui-header-tools-menu-bottom',
    getValueFn: (pos) => {
      switch (pos) {
        case 'top':
          return 'auto';
        default:
          return 0;
      }
    },
    defaultValue: {
      mobile: 'center',
      tabletLandscape: 'bottom',
    },
  },
  ..._.flatMap(
    ['cart', 'language', 'account', 'search', 'stores'],
    (item) => ([
      {
        key: 'config.headerToolsMenu',
        styleProperty: `--ui-header-tools-menu-item-${item}-display`,
        getValueFn: (menu) => (_.includes(menu, item) ? 'inline-flex' : 'none'),
        defaultValue: uiHeaderToolsMenuDefaultValue,
      },
      {
        key: 'config.headerToolsMenu',
        styleProperty: `--ui-header-tools-menu-item-${item}-order`,
        getValueFn: (menu) => _.indexOf(menu, item),
        defaultValue: uiHeaderToolsMenuDefaultValue,
      },
    ]),
  ),
  {
    key: 'config.headerToolsMenu',
    styleProperty: '--ui-header-tools-menu-item-account-dropdown-transform',
    getValueFn: (menu) => {
      if (!_.includes(menu, 'account')) return 'none'
      const rightSideItemCount = _.size(menu) - _.indexOf(menu, 'account') - 1
      if (rightSideItemCount > 1) {
        return 'translateX(-20%)'
      }
      return 'translateX(-70%)'
    },
    defaultValue: uiHeaderToolsMenuDefaultValue,
  },
  // -- header - burger menu --
  {
    key: 'config.headerBurgerMenuEnabled',
    styleProperty: '--ui-header-burger-menu-display',
    getValueFn: (enabled) => (enabled ? 'flex' : 'none'),
    defaultValue: {
      mobile: true,
      tabletLandscape: false,
    },
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-burger-menu-position',
    getValueFn: (pos) => {
      switch (pos) {
        case 'left':
          return 'static';
        default:
          return 'absolute';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerBurgerMenuLabelEnabled',
    getValueFn: (enabled) => (enabled ? 'block' : 'none'),
    styleProperty: '--ui-header-burger-menu-label-display',
    defaultValue: {
      mobile: false,
      tabletLandscape: true,
    },
  },
  // -- header - mega menu --
  {
    key: 'config.headerMegaMenuBackgroundColor',
    styleProperty: '--ui-header-mega-menu-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.headerMegaMenuEnabled',
    styleProperty: '--ui-header-mega-menu-display',
    getValueFn: (enabled) => (enabled ? 'block' : 'none'),
    defaultValue: {
      mobile: false,
      tabletLandscape: true,
    },
  },
  {
    key: 'config.headerMegaMenuBottomSpacing',
    styleProperty: '--ui-header-mega-menu-bottom-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerMegaMenuTopSpacing',
    styleProperty: '--ui-header-mega-menu-top-spacing',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 0,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerMegaMenuItemIndicatorStyle',
    styleProperty: '--ui-header-mega-menu-item-indicator-top',
    getValueFn: (value) => {
      switch (value) {
        case 'background':
          return 0
        case 'tab':
          return '0.2rem'
        default:
          return 'calc(100% - 0.2rem)'
      }
    },
    defaultValue: 'bottomLine',
  },
  {
    key: 'config.headerMegaMenuItemIndicatorStyle',
    styleProperty: '--ui-header-mega-menu-item-indicator-left',
    getValueFn: (value) => {
      switch (value) {
        case 'background':
        case 'tab':
          return '-1em'
        default:
          return 0
      }
    },
    defaultValue: 'bottomLine',
  },
  {
    key: 'config.headerMegaMenuItemIndicatorStyle',
    styleProperty: '--ui-header-mega-menu-item-indicator-right',
    getValueFn: (value) => {
      switch (value) {
        case 'background':
        case 'tab':
          return '-1em'
        default:
          return 0
      }
    },
    defaultValue: 'bottomLine',
  },
  {
    key: [
      'config.headerMegaMenuItemTextColor',
      'config.headerColorText',
      'colors.primary',
    ],
    styleProperty: '--ui-header-mega-menu-item-text-color',
    defaultValue: 'currentColor',
  },
  {
    key: [
      'config.headerMegaMenuItemActiveColor',
      'colors.hover',
    ],
    styleProperty: '--ui-header-mega-menu-item-active-color',
    defaultValue: 'currentColor',
  },
  {
    key: [
      'config.headerMegaMenuItemActiveTextColor',
      'config.headerMegaMenuItemActiveColor',
      'colors.hover',
    ],
    styleProperty: '--ui-header-mega-menu-item-active-text-color',
    defaultValue: 'currentColor',
  },
  // -- header - Order Method --
  {
    key: 'config.headerOrderMethodBorderWidth',
    styleProperty: '--ui-header-order-method-border-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 1,
      tabletLandscape: 0,
    },
  },
  {
    key: 'config.headerOrderMethodBorderStyle',
    styleProperty: '--ui-header-order-method-border-style',
    defaultValue: 'solid',
  },
  {
    key: 'config.headerOrderMethodBorderColor',
    styleProperty: '--ui-header-order-method-border-color',
    defaultValue: mergedTheme.colors.bgMedium,
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-order-method-below-logo-display',
    getValueFn: (pos, mq) => {
      if (mq === 'mobile') {
        return 'block'
      }
      switch (pos) {
        case 'left':
        case 'right':
          return 'none';
        default:
          return 'block';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerLogoPosition',
    styleProperty: '--ui-header-order-method-near-logo-display',
    getValueFn: (pos, mq) => {
      if (mq === 'mobile') {
        return 'none'
      }
      switch (pos) {
        case 'left':
        case 'right':
          return 'block';
        default:
          return 'none';
      }
    },
    defaultValue: {
      mobile: 'center',
    },
  },
  {
    key: 'config.headerOrderMethodPosition',
    styleProperty: '--ui-header-order-method-below-logo-row-justify-content',
    getValueFn: (pos) => {
      switch (pos) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        default:
          return 'center';
      }
    },
    defaultValue: {
      mobile: 'left',
      tabletLandscape: 'center',
    },
  },
  // -- sidebar menu --
  {
    key: 'config.ui.sidebarMenu.loginRegisterButtonsEnabled',
    styleProperty: '--ui-sidebar-menu-account-buttons-display',
    getValueFn: (enabled) => (enabled ? 'flex' : 'none'),
    defaultValue: true,
  },
  {
    key: ['config.ui.sidebarMenu.searchEnabled', 'config.sidebarMenuSearchEnabled'],
    styleProperty: '--ui-sidebar-menu-search-display',
    getValueFn: (enabled) => (enabled ? 'block' : 'none'),
    defaultValue: {
      mobile: true,
      tabletLandscape: false,
    },
  },
  // -- product list --
  {
    key: 'config.ui.productList.numberOfColumns',
    styleProperty: '--ui-product-list-item-width',
    getValueFn: (value, mq) => {
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: {
      mobile: 2,
      tablet: 4,
    },
  },
  {
    key: 'config.ui.productList.gap',
    styleProperty: '--ui-product-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 16,
      tablet: 30,
    },
  },
  // -- product card --
  {
    key: 'config.ratioPlp',
    styleProperty: '--ui-product-card-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  {
    key: 'config.ratioPlp',
    styleProperty: '--ui-product-card-image-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: 'auto',
  },
  {
    key: 'config.ui.productCard.thumbnail.roundedCorners',
    styleProperty: '--ui-product-card-image-border-radius',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productCard.price.position',
    styleProperty: '--ui-product-card-price-container-justify-content',
    getValueFn: (value) => {
      switch (value) {
        case 'bottom':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    },
    defaultValue: 'auto',
  },
  {
    key: 'config.ui.productCard.price.fontSize',
    styleProperty: '--ui-product-card-price-font-size',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 16,
      tablet: 18,
    },
  },
  {
    key: ['config.ratioPlpSwatch', 'config.ratioThumbnail'],
    styleProperty: '--ui-product-card-swatch-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-flex-direction',
    getValueFn: (value) => {
      if (value === 'left') {
        return 'row'
      }
      if (value === 'right') {
        return 'row-reverse'
      }
      return 'column'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-thumbnail-width',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return '36%'
      }
      return 'auto'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-thumbnail-max-width',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return '12rem'
      }
      return 'none'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-content-padding',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 0
      }
      return '1rem 1rem 2rem'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-content-text-align',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'left'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-content-align-items',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'flex-start'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-custom-labels-justify-content',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'flex-start'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.thumbnail.position',
    styleProperty: '--ui-product-card-price-list-justify-content',
    getValueFn: (value) => {
      if (value === 'left' || value === 'right') {
        return 'flex-start'
      }
      return 'center'
    },
    defaultValue: 'top',
  },
  {
    key: 'config.ui.productCard.padding',
    styleProperty: '--ui-product-card-padding',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  {
    key: 'colors.ui.productCard.backgroundColor',
    styleProperty: '--ui-product-card-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.productCard.roundedCorners',
    styleProperty: '--ui-product-card-border-radius',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productCard.thumbnail.roundedCorners',
    styleProperty: '--ui-product-card-image-border-radius',
    getValueFn: (value) => {
      if (_.isNil(value)) {
        return 'var(-ui-product-card-image-border-radius)'
      }
      if (_.isBoolean(value)) {
        return value ? '0.8rem' : 0
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.productCard.price.position',
    styleProperty: '--ui-product-card-price-container-justify-content',
    getValueFn: (value) => {
      if (_.isEmpty(value)) return 'var(--ui-product-card-price-container-justify-content)'
      switch (value) {
        case 'bottom':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    },
  },
  {
    key: 'config.ui.productCard.enableDetails',
    styleProperty: '--ui-product-card-content-details-display',
    getValueFn: (value) => {
      if (_.isBoolean(value)) {
        return value ? '-webkit-box' : 'none'
      }
      return value
    },
    defaultValue: false,
  },
  {
    key: 'config.ui.favouriteButton.enableBackground',
    styleProperty: '--ui-favourite-button-background-display',
    getValueFn: (value) => {
      if (_.isBoolean(value)) return value ? 'block' : 'none'
      return value
    },
    defaultValue: false,
  },
  // -- product group list --
  {
    key: 'config.ui.productGroup.list.gap',
    styleProperty: '--ui-product-group-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-product-list-gap)',
  },
  {
    key: 'config.ui.productGroup.numberOfColumns',
    styleProperty: '--ui-product-group-item-width',
    getValueFn: (value, mq) => {
      if (_.isString(value) && _.startsWith(value, 'var(--')) return value
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: 'var(--ui-product-list-item-width)',
  },

  // -- mini cart --
  {
    key: ['config.ratioCartImg', 'config.ratioThumbnail'],
    styleProperty: '--ui-mini-cart-products-item-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  // -- coupon sidebar --
  {
    key: 'config.ratioCouponDetailImg',
    styleProperty: '--ui-coupon-sidebar-detail-image',
    getValueFn: ratioCalc,
  },
  {
    key: ['config.ratioCouponsItemImg', 'config.ratioThumbnail'],
    styleProperty: '--ui-coupon-card-image-padding-bottom',
    getValueFn: ratioCalc,
  },
  // -- Footer --
  {
    key: ['config.ui.footer.backgroundColor', 'config.footerDownBackgroundColor', 'colors.background'],
    styleProperty: '--ui-footer-background-color',
    defaultValue: '#FFFFFF',
  },
  {
    key: ['config.ui.footer.textColor', 'colors.text'],
    styleProperty: '--ui-footer-text-color',
    defaultValue: '#222',
  },
  {
    key: ['config.ui.footer.menu.borderBottomWidth', 'config.ui.footer.menu.borderWidth'],
    styleProperty: '--ui-footer-menu-border-bottom-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 1,
  },
  {
    key: ['config.ui.footer.menu.borderBottomStyle', 'config.ui.footer.menu.borderStyle'],
    styleProperty: '--ui-footer-menu-border-bottom-style',
    defaultValue: 'solid',
  },
  {
    key: ['config.ui.footer.menu.borderBottomColor', 'config.ui.footer.menu.borderColor'],
    styleProperty: '--ui-footer-menu-border-bottom-color',
    defaultValue: mergedTheme.colors.disabled,
  },
  {
    key: ['config.ui.footer.menu.borderTopWidth', 'config.ui.footer.menu.borderWidth'],
    styleProperty: '--ui-footer-menu-border-top-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 1,
  },
  {
    key: ['config.ui.footer.menu.borderTopStyle', 'config.ui.footer.menu.borderStyle'],
    styleProperty: '--ui-footer-menu-border-top-style',
    defaultValue: 'solid',
  },
  {
    key: ['config.ui.footer.menu.borderTopColor', 'config.ui.footer.menu.borderColor'],
    styleProperty: '--ui-footer-menu-border-top-color',
    defaultValue: mergedTheme.colors.disabled,
  },
  {
    key: ['config.ui.footer.menu.backgroundColor', 'config.footerUpBackgroundColor'],
    styleProperty: '--ui-footer-menu-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.footer.logo.backgroundColor',
    styleProperty: '--ui-footer-logo-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.footer.logo.textColor',
    styleProperty: '--ui-footer-logo-text-color',
  },
  {
    key: 'config.ui.footer.logo.width',
    getValueFn: parseThemeStyleUnit,
    styleProperty: '--ui-footer-logo-width',
    defaultValue: 'var(--ui-header-logo-width)',
  },
  {
    key: ['config.ui.footer.links.backgroundColor', 'config.footerDownBackgroundColor'],
    styleProperty: '--ui-footer-links-background-color',
    defaultValue: 'transparent',
  },
  {
    key: 'config.ui.footer.bottomBar.backgroundColor',
    styleProperty: '--ui-footer-bottom-bar-background-color',
    defaultValue: '#000000',
  },
  {
    key: 'config.ui.footer.bottomBar.textColor',
    styleProperty: '--ui-footer-bottom-bar-text-color',
    defaultValue: '#ffffff',
  },
  {
    key: 'config.ui.footer.bottomBar.textAlign',
    styleProperty: '--ui-footer-bottom-bar-text-align',
    defaultValue: 'center',
  },
  {
    key: 'config.ui.footer.bottomBar.fontSize',
    styleProperty: '--ui-footer-bottom-bar-font-size',
    getValueFn: parseThemeStyleUnit,
  },
  {
    key: 'config.ui.footer.bottomBar.borderTopWidth',
    styleProperty: '--ui-footer-bottom-bar-border-top-width',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  {
    key: 'config.ui.footer.bottomBar.borderTopStyle',
    styleProperty: '--ui-footer-bottom-bar-border-top-style',
    defaultValue: 'solid',
  },
  {
    key: 'config.ui.footer.bottomBar.borderTopColor',
    styleProperty: '--ui-footer-bottom-bar-border-top-color',
    defaultValue: 'currentColor',
  },
  {
    key: 'config.ui.footer.bottomBar.topSpacing',
    styleProperty: '--ui-footer-bottom-bar-padding-top',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  {
    key: 'config.ui.footer.bottomBar.bottomSpacing',
    styleProperty: '--ui-footer-bottom-bar-padding-bottom',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 0,
  },
  // -- List --
  {
    key: 'config.ui.list.title.color',
    styleProperty: '--ui-list-title-color',
    defaultValue: 'currentColor',
  },
  {
    key: 'config.ui.list.item.color',
    styleProperty: '--ui-list-item-color',
    defaultValue: 'currentColor',
  },
  {
    key: 'config.ui.list.item.linkPrefix',
    styleProperty: '--ui-list-item-link-prefix',
    defaultValue: '"-"',
  },
  // -- content groups --
  {
    key: 'config.ui.contentGroup.imageList.numberOfColumns',
    styleProperty: '--ui-content-group-image-list-number-of-columns',
    defaultValue: {
      mobile: 2,
      tablet: 3,
    },
  },
  {
    key: 'config.ui.contentGroup.imageList.gap',
    styleProperty: '--ui-content-group-image-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: {
      mobile: 16,
      tablet: 30,
    },
  },
  {
    key: 'config.ui.contentGroup.imageList.item.name.color',
    styleProperty: '--ui-content-group-image-list-item-name-color',
    defaultValue: '#ffffff',
  },
  // ## pages ##
  // -- account favourites --
  {
    key: 'config.pages.account.favourites.list.gap',
    styleProperty: '--pages-account-favourites-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-product-list-gap)',
  },
  {
    key: 'config.pages.account.favourites.numberOfColumns',
    styleProperty: '--pages-account-favourites-item-width',
    getValueFn: (value, mq) => {
      if (_.isString(value) && _.startsWith(value, 'var(--')) return value
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: 'var(--ui-product-list-item-width)',
  },
  // -- account order --
  {
    key: ['config.ratioAccountOrdersItemImg', 'config.ratioThumbnail'],
    styleProperty: '--pages-account-orders-item-thumbnail-padding-bottom',
    getValueFn: ratioCalc,
  },
  {
    key: ['config.ratioAccountOrderProductImg', 'config.ratioThumbnail'],
    styleProperty: '--pages-account-order-product-thumbnail-padding-bottom',
    getValueFn: ratioCalc,
  },
  // -- cart --
  {
    key: ['config.ratioCartImg', 'config.ratioThumbnail'],
    styleProperty: '--pages-cart-item-thumbnail-padding-bottom',
    getValueFn: ratioCalc,
  },
  // -- categories --
  {
    key: 'config.pages.categories.numberOfColumns',
    styleProperty: '--pages-categories-item-width',
    getValueFn: (value) => {
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((1.6 * numGaps) / numCol, 2)}rem)`
    },
    defaultValue: {
      mobile: 2,
      tablet: 4,
    },
  },
  {
    key: ['config.ratioCategoryThumb'],
    styleProperty: '--pages-categories-item-thumbnail-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '4/3',
  },
  // -- products --
  {
    key: 'config.pages.products.pagination.display',
    styleProperty: '--pages-products-pagination-top-display',
    getValueFn: (value) => (_.includes(['top', 'both'], value) ? 'block' : 'none'),
    defaultValue: 'both',
  },
  {
    key: 'config.pages.products.pagination.display',
    styleProperty: '--pages-products-pagination-bottom-display',
    getValueFn: (value) => (_.includes(['bottom', 'both'], value) ? 'block' : 'none'),
    defaultValue: 'both',
  },
  {
    key: 'config.pages.products.numberOfColumns',
    styleProperty: '--pages-products-item-width',
    getValueFn: (value, mq) => {
      if (_.isString(value) && _.startsWith(value, 'var(--')) return value
      const numCol = _.toNumber(value)
      const numGaps = numCol - 1
      const gapWidth = _.includes(['mobile'], mq) || _.isNil(mq) ? 1.6 : 3
      return `calc(${_.floor(100 / numCol, 2)}% - ${_.ceil((gapWidth * numGaps) / numCol, 2)}rem)`
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: 'var(--ui-product-list-item-width)',
  },
  {
    key: 'config.pages.products.list.gap',
    styleProperty: '--pages-products-list-gap',
    getValueFn: parseThemeStyleUnit,
    defaultValue: 'var(--ui-product-list-gap)',
  },
  // -- products comparison --
  {
    key: 'config.ratioPlp',
    styleProperty: '--pages-products-comparison-thumbnail-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '1:1',
  },
  // -- product detail --
  {
    key: 'config.ratioPdp',
    styleProperty: '--pages-product-image-aspect-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '1:1',
  },
  {
    key: 'config.ui.images.zoom.enabled',
    styleProperty: '--pages-product-image-zoom-button-display',
    getValueFn: (value = true) => (value ? 'block' : 'none'),
    defaultValue: true,
  },
  // -- stores --
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-flex-wrap',
    getValueFn: (value) => {
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? 'wrap' : 'nowrap'
    },
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-hero-order',
    getValueFn: (value, mq) => {
      if (_.includes(['mobile'], mq) || _.isNil(mq)) return 1
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? 1 : 3
    },
    forceRenderMediaQueries: ['mobile', 'tablet'],
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-hero-flex-basis',
    getValueFn: (value) => {
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? '100%' : '36%'
    },
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-content-flex-basis',
    getValueFn: (value) => {
      const heroImageRatio = parseAspectRatio(value)
      const heroImageRatioInNumber = _.divide(
        ..._.map(
          _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
          _.toNumber,
        ),
      )
      const isHeroImageTooWide = heroImageRatioInNumber > 1.5
      return isHeroImageTooWide ? '50%' : '28%'
    },
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsImageBanner',
    styleProperty: '--pages-store-locations-item-image-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: '1/1',
  },
  {
    key: 'config.ratioStoreLocationsMap',
    styleProperty: '--pages-store-locations-item-map-ratio',
    getValueFn: parseAspectRatio,
    defaultValue: 'var(--pages-store-locations-item-image-ratio)',
  },
])
export default getThemeConfigStylesRegistrationList
