import _ from 'lodash'
import { createUseStyles } from 'react-jss'
import Color from 'color';

const useStyles = createUseStyles(
  ({
    alert = {},
    // wrapper = {},
    message = {},
    closeButton = {},
    cross = {},
    show = {},
    colors,
    mediaQueries,
  }) => {
    const { defaultStyles = {}, override = {}, ...modifiers } = alert
    // const {
    // //   defaultStyles: wrapperDefaultStyles = {},
    // //   override: wrapperOverride = {},
    // //   ...wrapperModifiers
    // // } = wrapper
    const {
      defaultStyles: messageDefaultStyles = {},
      override: messageOverride = {},
      ...messageModifiers
    } = message
    const {
      defaultStyles: closeButtonDefaultStyles = {},
      override: closeButtonOverride = {},
      ...closeButtonModifiers
    } = closeButton
    const {
      defaultStyles: crossDefaultStyles = {},
      override: crossOverride = {},
      ...crossModifiers
    } = cross
    return {
      alert: {
        backgroundColor: (props) => (
          props.state === 'success'
            ? `${Color(colors.success).alpha(0.1).string()}`
            : `${Color(colors.alert).alpha(0.1).string()}`
        ),
        position: 'relative',
        color: (props) => (
          props.state === 'success'
            ? colors.success
            : colors.alert
        ),
        overflow: 'hidden',
        width: '100%',
        borderRadius: '5px',
        border: (props) => (
          props.state === 'success'
            ? `1px solid ${colors.success}`
            : `1px solid ${colors.alert}`
        ),
        padding: '1.6rem',
        [`@media (min-width: ${mediaQueries.tabletLandscape}px)`]: {
          position: 'fixed',
          zIndex: 10,
          top: 0,
          left: '50%',
          maxWidth: 1200,
          transform: 'translateX(-50%)',
        },
      },
      wrapper: (props) => ({
        position: 'relative',
        overflow: 'visible',
        maxHeight: 0,
        transition: 'max-height 0.4s',
        padding: '0 2rem',
        marginBottom: '1rem',
        width: '100%',

        ...defaultStyles,

        ...(props.show
          ? {
            maxHeight: props.maxHeight,
            ...show,
          }
          : {}),
        ..._.reduce(
          modifiers,
          (result, styles, modifier) => (
            props[modifier]
              ? {
                ...result,
                ...styles,
              }
              : result
          ),
          {},
        ),

        ...override,
      }),
      message: (props) => ({
        fontSize: '1.6rem',
        fontWeight: 'normal',
        lineHeight: '1.6rem',
        letterSpacing: '0.05rem',
        ...messageDefaultStyles,
        ..._.reduce(
          messageModifiers,
          (result, styles, modifier) => (
            props[modifier]
              ? {
                ...result,
                ...styles,
              }
              : result
          ),
          {},
        ),

        ...messageOverride,
      }),
      closeButton: (props) => ({
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '1.6rem',
        cursor: 'pointer',
        outline: 'none',
        '& img': {
          marginBottom: 0,
        },
        ...closeButtonDefaultStyles,
        ..._.reduce(
          closeButtonModifiers,
          (result, styles, modifier) => (
            props[modifier]
              ? {
                ...result,
                ...styles,
              }
              : result
          ),
          {},
        ),

        ...closeButtonOverride,
      }),
      cross: (props) => ({
        '& path': {
          fill: 'currentColor',
        },

        ...crossDefaultStyles,
        ..._.reduce(
          crossModifiers,
          (result, styles, modifier) => (
            props[modifier]
              ? {
                ...result,
                ...styles,
              }
              : result
          ),
          {},
        ),

        ...crossOverride,
      }),
    }
  },
)

export default useStyles
