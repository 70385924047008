/* eslint-disable no-param-reassign */
import _ from 'lodash'

/*
 ** Turn style value from theme to style with media query support
 */
export default function getStyleFromTheme({
  theme,
  key, // key || [key]
  defaultValue,
  getValueFn = _.identity,
  styleProperty,
  forceRenderMediaQueries = [],
  isRoot = false,
}) {
  if (_.isEmpty(theme) || _.isEmpty(key) || _.isEmpty(styleProperty)) return {}
  const isValueEmpty = (v) => _.isUndefined(v) || v === ''
  const availableOrderMethodCodes = _.map(_.get(theme, 'config.availableOrderMethod', []), ({ code }) => _.camelCase(code))
  let value = _.first(_.reject(_.at(theme, _.castArray(key)), isValueEmpty))
  if (_.isUndefined(value)) {
    value = defaultValue
  }
  // Don't render any style if value is undefined
  if (_.isNil(value)) return {}

  const isOrderMethodSpecified = !_.isEmpty(
    _.intersection(_.keys(value), availableOrderMethodCodes),
  )
  // transform value to `{[orderMethodCode]: value, default: value}`
  if (
    !_.isObject(value)
    || !isOrderMethodSpecified
  ) {
    value = {
      default: value,
    }
  }
  if (!_.has(value, 'default')) {
    value = {
      ...value,
      default: defaultValue,
    }
  }

  const mq = _.sortBy(
    _.map(_.get(theme, 'mediaQueries', {}), (width, mqKey) => ({ width, mqKey })),
    'width',
  )
  const mqKeys = _.map(mq, 'mqKey')
  let style
  _.forEach(value, (v, orderMethodCode) => {
    let temp
    let val = v
    const frmq = _.intersection(mqKeys, forceRenderMediaQueries)
    if (
      _.isArray(forceRenderMediaQueries)
      && !_.isEmpty(frmq)
    ) {
      val = {
        ..._.zipObject(
          frmq,
          _.map(frmq, (mediaQuery) => {
            const fallbackMediaQueryKeys = _.concat(
              _.reverse(
                _.dropRight(
                  mqKeys,
                  _.size(mqKeys) - (_.indexOf(mqKeys, mediaQuery) + 1),
                ),
              ),
              _.slice(mqKeys, _.indexOf(mqKeys, mediaQuery) + 1),
            )
            return _.isObject(val)
              ? _.first(_.reject(_.at(val, fallbackMediaQueryKeys), isValueEmpty))
              : val
          }),
        ),
        ...(
          _.isObject(val) ? val : {}
        ),
      }
    }
    if (
      _.isObject(val)
      && !_.isEmpty(
        _.intersection(
          mqKeys,
          _.keys(val),
        ),
      )
    ) {
      const extendedStyles = _.reduce(
        _.filter(mq, ({ mqKey }) => _.includes(_.keys(val), mqKey)),
        (result, { mqKey, width }) => {
          const output = _.get(val, mqKey)
          const propertyValue = getValueFn(output, mqKey)
          if (propertyValue === false) {
            return result
          }
          if (_.isEmpty(result)) {
            result = [{
              [styleProperty]: propertyValue,
            }]
          } else {
            result = [
              ...result,
              {
                [`@media (min-width: ${width}px)`]: {
                  [styleProperty]: propertyValue,
                },
              },
            ]
          }
          return result
        },
        [],
      )
      // Use an array of style objects with extend feature of jss-plugin-extend
      //  to make sure the order of media queries are preserved
      // ref: https://cssinjs.org/jss-plugin-extend?v=v10.10.0
      temp = {
        extend: extendedStyles,
      }
    } else {
      const propertyValue = getValueFn(val)
      if (propertyValue !== false) {
        temp = {
          extend: [{
            [styleProperty]: propertyValue,
          }],
        }
      }
    }
    if (orderMethodCode === 'default') {
      style = isRoot
        ? {
          ':root': temp,
          ...style,
        }
        : {
          ...style,
          ...temp,
        }
    } else {
      style = {
        ...style,
        [`[data-order-method="${orderMethodCode}"]${isRoot ? '' : ' &'}`]: temp,
      }
    }
  })

  /// ///// output ///////
  /// single mq style
  /// { extend: [{ padding: 0 }] }
  /// multi-mq styles
  /// { extend: [{ padding: 0 }, { '@media (min-width: 768px)': { padding: 1rem } }] }
  /// Order method specified styles
  /// { extend: [...], '[data-order-method="ecom"] &': { extend: [...] } }
  /// Order method specified styles with `isRoot: true`
  /// { ':root': { extend: [...] }, '[data-order-method="ecom"]': { extend: [...] } }

  return style
}
