import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  container: {
    padding: '1.5rem 0',
    textAlign: 'left',
  },
  titleStyle: {
    fontSize: '1.6rem',
    lineHeight: 1.5,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '0.6rem',
    color: 'var(--ui-list-title-color)',
  },
  listItemsStyle: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  imageIcon: {
    maxWidth: 130,
  },
  itemLinkStyle: {
    fontWeight: 400,
    display: 'block',
    marginRight: 0,
    lineHeight: 1,
    color: 'var(--ui-list-item-color)',
    '& a': {
      color: 'currentColor',
      textDecoration: 'none',
      display: 'block',
      '&:before': {
        // content: '"-"',
        content: 'var(--ui-list-item-link-prefix)',
        marginRight: 5,
        display: 'inline',
      },
    },
  },
  itemLinkImageStyle: {
    '& a:before': {
      display: 'none',
    },
  },
  itemLinkTemplateStyle: {
    fontWeight: 300,
    display: 'block',
    lineHeight: 1.25,
    '& a': {
      textDecoration: 'none',
    },
    'html[lang^=zh] &': {
      fontSize: '1.4rem',
    },
  },
  itemLinkTagStyle: {
    display: 'inline',
    marginRight: 8,
    '& a': {
      display: 'inline',
      '&:before': {
        content: '"#"',
        marginRight: 0,
      },
    },
  },
  itemLinkInlineStyle: {
    display: 'inline-block',
    margin: '0 .8rem',
    fontSize: '1.4rem',
    fontWeight: 400,
    '& a': {
      textDecoration: 'none',
    },
  },
  itemIconStyle: {
    display: 'inline-block',
    marginTop: '1rem',
    '& img': {
      width: '3rem',
      marginRight: '2rem',
    },
  },
  itemLinkIconStyle: {
    position: 'relative',
    display: 'block',
    width: 30,
    marginRight: 15,
  },
  icon: {
    position: 'absolute',
    left: 10,
    top: 10,
    transform: 'scale(2.3)',
  },
  itemButtonStyle: {
    marginLeft: 0,
    '& a': {
      textDecoration: 'none',
      fontSize: 14,
      fontWeight: 600,
    },
    '& img': {
      margin: 0,
      marginLeft: 10,
    },
  },
  '@media (min-width: 1170px)': {
    itemButtonStyle: {
      marginLeft: -15,
    },
    container: {
      padding: '1.5rem 1.6rem',
    },
  },
}))

export default useStyles
