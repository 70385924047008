import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useI18n, useSystemSettings } from 'react-omnitech-api'
import moment from 'moment/min/moment-with-locales'

import LocaleContext from './locale-context'
import setupI18next from './setup-i18next'

const withI18next = () => (Comp) => {
  const i18n = setupI18next()

  function I18nHOC(props) {
    // prepare hook
    const { getSystemSetting } = useSystemSettings()

    // local variable
    const availableLocales = _.get(props, 'config.locale.availableLocales', ['en-HK'])
    const defaultLocale = _.get(props, 'config.locale.defaultLocale', 'en-HK')
    const localeConifg = _.get(props, 'config.locale.config', {})
    const locale = _.get(props, 'pageContext.locale', defaultLocale)
    const currentLocale = localeConifg[locale] || localeConifg[defaultLocale]
    const verdorKey = getSystemSetting('vendor_specific_system_setting_key', '')
    const translationOverride = _.get(
      getSystemSetting(`vendor_specific_settings.${verdorKey}.translation.override.ecom`, {}),
      _.camelCase(locale),
      {},
    )

    // get change language from for omnitech lib
    const { changeLanguage: changeOmnitechLanguage } = useI18n()

    // load translation resources base on website locale
    function addResources(pageContext) {
      if (pageContext && pageContext.localeResources && pageContext.localeResources.translation) {
        const {
          locale: lng,
          localeResources: { translation },
        } = pageContext
        const resourceBundle = i18n.getResourceBundle(lng, 'translation')
        if (!_.isEqual(_.merge(translation, translationOverride), resourceBundle)) {
          i18n.addResourceBundle(
            lng,
            'translation',
            _.merge(translation, translationOverride),
          )
        }
      }
    }

    function getLocaleConfig(localeCode) {
      return localeConifg[localeCode] || {}
    }

    function getLocalePath(localeCode) {
      const config = getLocaleConfig(localeCode)
      return config.path || 'en-HK'
    }

    function changeLanguage() {
      const { pageContext } = props
      addResources(pageContext)
      i18n.changeLanguage(pageContext.locale)
      changeMomentLanguage(pageContext.locale)
      changeOmnitechLanguage(pageContext.locale)
    }

    function changeMomentLanguage(loc) {
      // Set moment locale at app launch
      const arraySplitChar = i18n.t('ui.moment.arraySplitChar')
      moment.locale(
        (loc || 'en').toLowerCase(),
        {
          months: i18n.t('ui.moment.months').split(arraySplitChar),
          monthsShort: i18n.t('ui.moment.monthsShort').split(arraySplitChar),
          weekdays: i18n.t('ui.moment.weekdays').split(arraySplitChar),
          weekdaysShort: i18n.t('ui.moment.weekdaysShort').split(arraySplitChar),
          weekdaysMin: i18n.t('ui.moment.weekdaysMin').split(arraySplitChar),
          longDateFormat: {
            LT: i18n.t('ui.moment.longDateFormat.LT'),
            LTS: i18n.t('ui.moment.longDateFormat.LTS'),
            L: i18n.t('ui.moment.longDateFormat.L'),
            LL: i18n.t('ui.moment.longDateFormat.LL'),
            LLL: i18n.t('ui.moment.longDateFormat.LLL'),
            LLLL: i18n.t('ui.moment.longDateFormat.LLLL'),
            l: i18n.t('ui.moment.longDateFormat.l'),
            ll: i18n.t('ui.moment.longDateFormat.ll'),
            lll: i18n.t('ui.moment.longDateFormat.lll'),
            llll: i18n.t('ui.moment.longDateFormat.llll'),
          },
          calendar: {
            sameDay: i18n.t('ui.moment.calendar.sameDay'),
            nextDay: i18n.t('ui.moment.calendar.nextDay'),
            nextWeek: i18n.t('ui.moment.calendar.nextWeek'),
            lastDay: i18n.t('ui.moment.calendar.lastDay'),
            lastWeek: i18n.t('ui.moment.calendar.lastWeek'),
            sameElse: i18n.t('ui.moment.calendar.sameElse'),
          },
          relativeTime: {
            future: i18n.t('ui.moment.relativeTime.future'),
            past: i18n.t('ui.moment.relativeTime.past'),
            s: i18n.t('ui.moment.relativeTime.s'),
            ss: i18n.t('ui.moment.relativeTime.ss'),
            m: i18n.t('ui.moment.relativeTime.m'),
            mm: i18n.t('ui.moment.relativeTime.mm'),
            h: i18n.t('ui.moment.relativeTime.h'),
            hh: i18n.t('ui.moment.relativeTime.hh'),
            d: i18n.t('ui.moment.relativeTime.d'),
            dd: i18n.t('ui.moment.relativeTime.dd'),
            M: i18n.t('ui.moment.relativeTime.M'),
            MM: i18n.t('ui.moment.relativeTime.MM'),
            y: i18n.t('ui.moment.relativeTime.y'),
            yy: i18n.t('ui.moment.relativeTime.yy'),
          },
          ...(
            /^en(:?-\w+|$)/.test(loc)
              ? {
                dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
                ordinal(number) {
                  const b = number % 10
                  if (Math.floor((number % 100) / 10) === 1) {
                    return `${number}th`
                  }
                  switch (b) {
                    case 1:
                      return `${number}st`
                    case 2:
                      return `${number}nd`
                    case 3:
                      return `${number}rd`
                    default:
                      return `${number}th`
                  }
                },
              }
              : {}
          ),
        },
      )
    }

    // sync language between website and omnitech lib
    useEffect(() => {
      changeLanguage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const translationOverrideDependencies = useMemo(
      () => (JSON.stringify(translationOverride)),
      [translationOverride],
    )
    const currentLocaleCode = useMemo(() => _.get(currentLocale, 'locale', locale), [currentLocale])
    useEffect(() => {
      changeLanguage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translationOverrideDependencies, currentLocaleCode])

    return (
      <LocaleContext.Provider
        value={{
          availableLocales,
          currentLocale,
          locale,
          getLocalePath,
        }}
      >
        <I18nextProvider i18n={i18n}>
          <Comp {...props} />
        </I18nextProvider>
      </LocaleContext.Provider>
    )
  }

  return I18nHOC
}

export default withI18next
