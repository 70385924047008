/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  containerMultiLine: {
    padding: '0 1.1em',
  },
  divider: {
    display: 'block',
    width: '0.1rem',
    height: '1em',
    margin: '0 1rem 0.16667em',
    backgroundColor: theme.colors.bgDark,
  },
  dividerForMultiLine: {
    display: 'none',
  },
  item: {},
}))

export default useStyles
