import _ from 'lodash'

export default function getBannerName(
  filters,
) {
  const department = _.get(filters, 'departmentCodeEq')
  const category = _.get(filters, 'categoryCodeEq')
  const brands = _.castArray(_.get(filters, 'brandCodeEq', []))
  switch (true) {
    case !_.isEmpty(department) && !_.isEmpty(category):
      return `ecom_plp_banner_${department}_${category}`

    case !_.isEmpty(department):
      return `ecom_plp_banner_${department}`

    case !_.isEmpty(category):
      return `ecom_plp_banner_${category}`

    case _.size(brands) === 1:
      return `ecom_plp_banner_brand_${_.first(brands)}`

    default:
      return 'ecom_plp_banner_all'
  }
}
