import { createUseStyles } from 'react-jss'
import themeConfigStyleService from '../../services/theme-config-style-service'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    backgroundColor: 'var(--ui-footer-background-color)',
    color: 'var(--ui-footer-text-color)',
    maxWidth: theme.config.backgroundUrl ? 1420 : '100%',
    margin: [[0, 'auto']],
    ...themeConfigStyleService.getStyles({
      theme,
      overrides: [
        {
          key: 'config.ui.footer.menu.titleColor',
          styleProperty: '--ui-list-title-color',
        },
        {
          key: 'config.ui.footer.menu.itemColor',
          styleProperty: '--ui-list-item-color',
        },
        {
          key: 'config.ui.footer.menu.itemHoverColor',
          styleProperty: '--ui-list-item-hover-color',
        },
        {
          key: 'config.ui.footer.menu.itemLinkPrefix',
          styleProperty: '--ui-list-item-link-prefix',
        },
      ],
    }),
  },
  containerLines: {
    borderBottomWidth: 'var(--ui-footer-menu-border-bottom-width)',
    borderBottomStyle: 'var(--ui-footer-menu-border-bottom-style)',
    borderBottomColor: 'var(--ui-footer-menu-border-bottom-color)',
    borderTopWidth: 'var(--ui-footer-menu-border-top-width)',
    borderTopStyle: 'var(--ui-footer-menu-border-top-style)',
    borderTopColor: 'var(--ui-footer-menu-border-top-color)',
    backgroundColor: 'var(--ui-footer-menu-background-color)',
    padding: '3rem 1.5rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1170,
    margin: [[0, 'auto']],
    minHeight: 132,
    '& h1': {
      margin: 0,
      maxWidth: 152,
    },
  },
  contentLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1.5rem',
    '& section': {
      paddingBottom: 0,
      flex: '1 1 50%',
      marginBottom: 10,
    },
    '& h5': {
      marginBottom: 6,
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    '& li a': {
      marginBottom: 0,
    },
  },
  containerLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '3.5rem',
    paddingBottom: '3.5rem',
    backgroundColor: 'var(--ui-footer-logo-background-color)',
    color: 'var(--ui-footer-logo-text-color)',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      minHeight: '25rem',
    },
    '& span': {
      fontSize: 14,
      color: 'currentColor',
    },
  },
  containerLinksFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    paddingBottom: 90,
    backgroundColor: 'var(--ui-footer-links-background-color)',
  },
  boxLogo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: 0,
    padding: [['0.2rem', 0]],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: 0,
    },
    maxWidth: 'var(--ui-footer-logo-width)',
  },
  logoImage: {
    display: 'block',
    width: '100%',
    margin: 0,
  },
  socialLink: {
    display: 'block',
    flex: '1 1 50%',
  },
  witgetLink: {
    flex: '1 1 100%',
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: 'auto',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      '& > div': {
        width: '100%',
      },
    },
  },
  socialLinkLogo: {
    display: 'none',
  },
  bottomBar: {
    backgroundColor: 'var(--ui-footer-bottom-bar-background-color)',
    color: 'var(--ui-footer-bottom-bar-text-color)',
    textAlign: 'var(--ui-footer-bottom-bar-text-align)',
    fontSize: 'var(--ui-footer-bottom-bar-font-size)',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    paddingTop: 'var(--ui-footer-bottom-bar-padding-top)',
    paddingBottom: 'var(--ui-footer-bottom-bar-padding-bottom)',
    borderTopWidth: 'var(--ui-footer-bottom-bar-border-top-width)',
    borderTopStyle: 'var(--ui-footer-bottom-bar-border-top-style)',
    borderTopColor: 'var(--ui-footer-bottom-bar-border-top-color)',
  },
  bottomBarContent: {
    color: 'currentColor',
    lineHeight: '1.2em',
    padding: '0.4em 0',
  },
  appVersion: {
    fontSize: '1rem',
    padding: '1rem',
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    container: {
      flexDirection: 'row',
    },
    socialLink: {
      display: 'none',
    },
    containerLines: {
      padding: 30,
    },
    contentLinks: ({ hasWidgetFacebook }) => ({
      flex: '1 1 55%',
      '& section': {
        paddingBottom: 30,
        flex: hasWidgetFacebook
          ? '1 1 33.33333333%'
          : '0 1 25%',
        marginBottom: 10,
        padding: '0 10px',
        '& > div': {
          margin: 0,
          padding: 0,
        },
      },
    }),
    witgetLink: {
      flex: '1 1 45%',
      justifyContent: 'flex-end',
      textAlign: 'left',

    },
    socialLinkLogo: {
      display: 'block',
      minHeight: '25rem',
      width: '15.5rem',
      paddingTop: '6.5rem',
      margin: [[0, 0, 0, '2rem']],
      '& > div': {
        padding: 0,
      },
      '& h5': {
        color: theme.config.footerUpColorText
          ? theme.config.footerUpColorText
          : theme.colors.primary,
      },
      '&:empty': {
        display: 'none',
      },
    },
  },
}))

export default useStyles
