import _ from 'lodash';
import React from 'react'
import { useTheme } from 'react-jss'
import combineClassNames from '../../../helpers/combineClassNames';
import Image from '../../image'
import Link from '../../link'
import useStyles from './content-group-style'

function TemplateImageList(props) {
  const {
    contentGroup,
    // onClickTrackEvent,
    // template,
    name: title,
    configOptions,
  } = props
  const { contentGroupLines, code } = contentGroup
  const theme = useTheme()
  const mediaQueries = _.get(theme, 'mediaQueries', {})
  const styles = useStyles(
    _.pick(configOptions, ['numberOfColumnsMobile', 'numberOfColumnsTablet', 'numberOfColumnsDesktop']),
  )
  // const onClick = (label) => {
  //   onClickTrackEvent(template, label)
  // }
  return (
    <div>
      {
        !_.isEmpty(title) && (
          <p className={combineClassNames([styles.title])}>
            {title}
          </p>
        )
      }
      <div
        className={combineClassNames([styles.row, styles.imageList, styles.imageListColumns, _.get(configOptions, 'htmlClass')])}
      >
        {
          _.map(contentGroupLines, (item, index) => {
            const {
              id,
              attachment,
              url,
              name,
              configOptions: itemConfigOptions,
            } = item
            const versions = {
              ..._.get(attachment, 'versions', {}),
              ..._.get(item, 'versions', {}),
            }
            const renderImage = (
              <Image
                src={_.get(versions, 'webMedium') || _.get(versions, 'ecomMedium')}
                srcSet={`${_.get(versions, 'webLarge') || _.get(versions, 'ecomLarge')} ${mediaQueries.tablet}w`}
                alt={name || ''}
                className={styles.imageListItemImage}
              />
            )
            return (
              <div
                className={combineClassNames([styles.imageListItem, _.get(itemConfigOptions, 'htmlClass')])}
                key={`${code}-image-${id || index}`}
              >
                {
                  _.isEmpty(url)
                    ? renderImage
                    : (
                      <Link
                        // CP-20210429: temporary removal as this sometimes
                        //   affects the click-through destination
                        // onClick={() => onClickTrackEvent(label)}
                        gatsbyLink
                        to={url}
                      >
                        {renderImage}
                      </Link>
                    )
                }
                {
                  !_.isEmpty(name)
                  && _.get(configOptions, 'enableItemName')
                  && (
                    <span className={styles.imageListItemName}>{name}</span>
                  )
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default TemplateImageList
