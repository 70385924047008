import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import {
  useOrderMethod,
} from 'react-omnitech-api'
import ThemeConfigContext from './theme-config-context'

/**
 * ThemeConfigProvider
 * Contain most logic handing navigate
 */
export default function ThemeConfigProvider({ children }) {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const {
    orderMethod,
  } = useOrderMethod()

  const availableOrderMethod = useMemo(() => (
    _.get(theme, 'config.availableOrderMethod', [])
  ), [theme])

  const selectableOrderMethod = useMemo(() => (
    _.filter(
      availableOrderMethod,
      ({ selectStoreBy }) => _.includes(['user', 'deliveryAddress'], selectStoreBy),
    )
  ), [availableOrderMethod])

  const orderMethodCode = useMemo(() => (
    _.get(orderMethod, 'code', 'default')
  ), [orderMethod])

  const getConfig = useCallback((key, fallback) => {
    let result = _.get(theme, `${key}.${orderMethodCode}`)
    if (_.isNil(result)) result = _.get(theme, `${key}.default`)
    if (_.isNil(result)) result = _.get(theme, key, fallback)
    return result
  }, [theme, orderMethodCode])

  const getContentGroup = useCallback((type = null, fallback = null) => {
    const camelCasedLocale = _.camelCase(i18n.language)

    // get code
    let code = null
    if (_.isEmpty(code)) code = getConfig(`${type}.${orderMethodCode}.contentGroup.${camelCasedLocale}`) // order method specific and locale specific
    if (_.isEmpty(code)) code = getConfig(`${type}.${orderMethodCode}.contentGroup.default`) // order method specific and locale specific (default)
    if (_.isEmpty(code)) code = getConfig(`${type}.${orderMethodCode}.contentGroup`) // order method specific and no locale specific
    if (_.isEmpty(code)) code = getConfig(`${type}.default.contentGroup.${camelCasedLocale}`) // order method (default) and locale specific
    if (_.isEmpty(code)) code = getConfig(`${type}.default.contentGroup.default`) // order method (default) and locale specific (default)
    if (_.isEmpty(code)) code = getConfig(`${type}.default.contentGroup`) // order method (default) and no locale specific
    if (_.isEmpty(code)) code = getConfig(`${type}.contentGroup.${camelCasedLocale}`) // locale specific
    if (_.isEmpty(code)) code = getConfig(`${type}.contentGroup.default`) // locale specific (default)
    if (_.isEmpty(code)) code = getConfig(`${type}.contentGroup`) // system setting string
    if (_.isEmpty(code)) code = _.get(fallback, 'code', fallback) // use fallback

    // get template
    let template = null
    if (_.isEmpty(template)) template = getConfig(`${type}.${orderMethodCode}.contentGroup.template`) // order method specific - maybe not in use
    if (_.isEmpty(template)) template = getConfig(`${type}.default.contentGroup.template`) // order method (default) - maybe not in use
    if (_.isEmpty(template)) template = getConfig(`${type}.${orderMethodCode}.template`) // order method specific
    if (_.isEmpty(template)) template = getConfig(`${type}.default.template`) // order method (default)
    if (_.isEmpty(template)) template = getConfig(`${type}.template`)
    if (_.isEmpty(template)) template = _.get(fallback, 'template', 'ContentGroup') // use fallback

    return {
      code,
      template,
    }
  }, [orderMethodCode])

  const state = {
    availableOrderMethod,
    getConfig,
    getContentGroup,
    selectableOrderMethod,
  };

  return (
    <ThemeConfigContext.Provider value={state}>
      {children}
    </ThemeConfigContext.Provider>
  )
}
