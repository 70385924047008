import { createUseStyles } from 'react-jss'
import Color from 'color'
import themeConfigStyleService from '../../services/theme-config-style-service'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    body: {
      // overflow: 'hidden',
      backgroundImage: theme.config.backgroundUrl && `url(${theme.config.backgroundUrl})`,
      // backgroundColor: theme.config.backgroundColor || '#fff',
      backgroundColor: 'var(--ui-body-background-color)',
      backgroundSize: theme.config.backgroundSize || 'cover !important',
      backgroundAttachment: theme.config.backgroundAttachment || 'fixed !important',
      backgroundRepeat: theme.config.backgroundRepeat || 'repeat',
      fontSize: theme.typo.textBodySize,
      fontFamily: [`${theme.typo.fontFamilyBody}`, 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
      color: theme.colors.text,
      fontSTretch: 'normal',
      lineHeight: 1.678,
      fontVariantLigatures: 'normal',
      fontVariantCaps: 'normal',
      fontVariantNumeric: 'normalize',
      fontVariantEastAsian: 'normal',
      fontFeatureSettings: 'kern, liga, clig, calt',
    },
    ...themeConfigStyleService.getStyles({
      theme,
      isRoot: true,
    }),
  },
  containerFluid: {
    padding: 0,
    width: '100%',
    // Jeri: Because function values inside function rules are not supported
    // need to set the padding top separately

    '&:before': {
      display: 'block',
      content: '""',
      backgroundColor: Color(theme.colors.overlay).alpha(0.4).string(),
      opacity: 0,
      position: 'absolute',
      zIndex: 1000,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      pointerEvents: 'none',
      visibility: 'hidden',
      transition: 'opacity 700ms ease, visibility 700ms ease',
    },
  },
  containerFluidOpen: {
    '&:before': {
      opacity: 1,
      transition: 'opacity 700ms ease, visibility 700ms ease',
      visibility: 'visible',
    },
  },
  contianerWrapper: {
    display: 'block',
    position: 'relative',
    transition: 'left 300ms',
    left: 0,
  },
  contianerWrapperOpen: {
    left: 'min(42rem, 85vw)',
    transition: 'left 300ms',
  },
  container: {
    maxWidth: theme.mediaQueries.desktop,
    backgroundColor: 'var(--ui-body-inner-background-color)',
    minHeight: '50rem',
    margin: 0,
    overflowX: 'hidden',
    '& [role=button], p, a, span, img, li, area, button, input, label, select, summary, textarea': {
      outline: 'none',
      tapHighlightColor: 'rgba(0,0,0,0)',
      touchAction: 'manipulation',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      margin: [[0, 'auto']],
      padding: '0 20px',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      maxWidth: `${theme.mediaQueries.desktop + 40}px`,
      width: '100%',
    },
  },
  header: {
    position: 'var(--ui-header-position)',
    zIndex: 12,
    top: 0,
    transition: 'left 300ms',
  },
  headerWithBackground: {
    maxWidth: `${theme.mediaQueries.desktop + 40}px`,
  },
  footerWithBackground: {
    maxWidth: `${theme.mediaQueries.desktop + 40}px`,
  },
  dialogModal: {
    paddingBottom: '3rem',
  },
  dialogModalOverlay: {
    zIndex: 99999,
  },
  dialogContainer: {
    margin: '0 auto',
    padding: '0.8rem 0',
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  dialogInfoButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '2rem',
  },
  dialogInfoButtonImage: {
    display: 'block',
    margin: 0,
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '3rem',
  },
  dialogDescription: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    margin: '3rem 0 0.4rem',
    '&:first-child': {
      marginTop: 0,
    },
  },
  dialogButtons: {
    maxWidth: '33.8rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '-1.6rem',
  },
  button: {
    marginBottom: '1.6rem',
  },
  memberNumber: {
    fontSize: '1.4rem',
    lineHeight: 1.25,
    marginTop: '1rem',
  },
  // FL: react-jss Asterisk selector nested under `@global` will make styles disapper sometime
  '@global *': {
    boxSizing: 'border-box',
  },
  '@global *:after': {
    boxSizing: 'border-box',
  },
  '@global *:before': {
    boxSizing: 'border-box',
  },
}))

export default useStyles
