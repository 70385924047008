/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import moment from 'moment'
import { navigate } from 'gatsby'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import {
  useBrands,
  useCart,
  useCategories,
  useCurrencies,
  useDepartments,
  useDineInCart,
  useMetaDefinitions,
  useStores,
  useSystemSettings,
  useUser,
  useOrderMethod,
} from 'react-omnitech-api'
import ReactGA from 'react-ga';
import {
  AlertProvider,
  CommerceChannelProvider,
  CouponMarketplaceProvider,
  DineInMiniCartProvider,
  GoogleMapsProvider,
  LocationProvider,
  LinkProvider,
  MenuProvider,
  MiniCartProvider,
  MobileMenuProvider,
  ModalProvider,
  OrderMethodModalProvider,
  CompareProvider,
  ThemeConfigProvider,
  // useOrderMethod, // useOrderMethod in hook dose not work here
} from '../../hook'
import Loading from '../app-loading'
import ThemeProvider from '../theme-provider'

/**
 * Application
 * Component to handle app init, preload required data from API
 * @param param0
 */
export default function Application({
  pageContext = {},
  config,
  children,
  location,
}) {
  const { locale } = pageContext
  const [ready, setReady] = useState(false)
  const [theme, setTheme] = useState({})
  // preload required data before app ready
  const { initCartForCartBadge } = useCart()
  const { initCartForCartBadge: initDineInCart } = useDineInCart()
  const { brandsReady } = useBrands()
  const { categoriesReady } = useCategories()
  const { currenciesReady } = useCurrencies()
  const { departmentsReady } = useDepartments()
  const { metaDefinitionsReady } = useMetaDefinitions()
  const { getSystemSetting, systemSettingsReady } = useSystemSettings()
  const { userReady } = useUser()
  const { orderMethod, store, setStore } = useOrderMethod()
  const { fetchStore, stores } = useStores()
  const trackingCode = getSystemSetting('analytics.google.tracking_code.ecom')
  const trackingGTMCode = getSystemSetting('analytics.google.tag_manager_code.ecom')
  const fnbEnabled = getSystemSetting('features.fnb.enable')

  // ready when required data is loaded
  const isReady = useMemo(
    () => (
      !_.includes([
        brandsReady, categoriesReady, currenciesReady,
        departmentsReady, systemSettingsReady, userReady,
        metaDefinitionsReady,
      ], false)
    ),
    [
      brandsReady, categoriesReady, currenciesReady,
      departmentsReady, systemSettingsReady, userReady,
      metaDefinitionsReady,
    ],
  )

  const orderMethodCode = useMemo(() => _.get(orderMethod, 'code'), [orderMethod])
  const getThemeConfig = useCallback(() => {
    const themeConfig = getSystemSetting('theme.ecom.config', {})
    setTheme(themeConfig)
  }, [getSystemSetting, setTheme])

  const updateStore = async (id) => {
    try {
      const includes = [
        'meta',
        'today_open_time_slots',
      ]
      const { store: updatedStore } = await fetchStore({ id, includes })
      // update store in localstorage (ensure only save store.id and store.code)
      setStore(_.pick(updatedStore, ['id', 'code']))
    } catch (error) {
      // do nothing
    }
  }

  useEffect(() => {
    if (!ready) return
    // Do nothing when scanning NFC
    if (/\/dine-in\/$/.test(_.get(location, 'pathname', ''))) return
    const selectedOrderMethodCode = _.get(orderMethod, 'code')
    const selectableOrderMethod = _.find(
      _.get(theme, 'config.availableOrderMethod', []),
      { code: selectedOrderMethodCode },
    )
    if (_.get(selectableOrderMethod, 'commerceType') === 'dineIn') {
      // FL: Only fetch dine-in cart in dine-in mode
      if (_.get(selectableOrderMethod, 'code') === 'dineIn') {
        // fetch dineInCart
        initDineInCart({
          includes: [
            'await_confirm_order',
            'physical_store_id',
            'carts.meta',
            'cart_shipments',
            'cart_shipments.inventory_store',
          ],
        })
          .catch(() => {
            // Nav to dine-in and show Dine-in completed message
            navigate(`/${locale}/dine-in/`)
          })
      }
      return
    }
    // check store is update to date in local storage
    const storeCode = _.get(store, 'code')
    const storeOpenTimeStart = _.get(_.find(stores, { code: storeCode }), 'todayOpenTimeSlots.0.start')

    if (
      !_.isEmpty(storeOpenTimeStart)
      && !moment(storeOpenTimeStart || '1900-01-01').isSame(new Date(), 'day')
    ) {
      updateStore(_.get(store, 'id'))
    }

    initCartForCartBadge({
      includes: [
        'await_confirm_order',
        'cart_shipments',
        'cart_shipments.inventory_store',
        'cart_line_properties.sku_id',
        // FL: Add Includes pickup store for store name on order method button
        ...(fnbEnabled ? [
          'cart_shipments.delivery_address',
          'cart_shipments.pickup_store',
          'available_delivery_types',
        ] : []),
      ],
    })
  }, [ready])

  // get theme config from system setting after it is ready
  useEffect(() => {
    if (!systemSettingsReady) return
    getThemeConfig()
  }, [getThemeConfig, systemSettingsReady])

  // load required data required by Omnitech
  useEffect(() => {
    if (isReady && !ready) {
      setReady(isReady)
    }
  }, [isReady, ready])

  useEffect(() => {
    if (!_.isEmpty(trackingCode)) {
      ReactGA.initialize(trackingCode);
      ReactGA.pageview(window.location.pathname + window.location.search)
      ReactGA.plugin.require('ec')
    }
  }, [trackingCode, trackingGTMCode])

  return ready
    ? (
      <ThemeProvider theme={theme}>
        {/*
          // Using Link hook to support navigate with locale prepended in link
        */}
        <LinkProvider value={{ locale }}>
          <LocationProvider value={{ location, locale }}>
            <ThemeConfigProvider>
              <CommerceChannelProvider value={{ location, locale }}>
                <MenuProvider>
                  <AlertProvider>
                    <ModalProvider>
                      <OrderMethodModalProvider>
                        <DineInMiniCartProvider>
                          <MiniCartProvider>
                            <MobileMenuProvider>
                              <CouponMarketplaceProvider>
                                <CompareProvider>
                                  <GoogleMapsProvider apiKey={_.get(config, 'service.googleMapsApiKey')}>
                                    <Helmet htmlAttributes={{ 'data-order-method': orderMethodCode || 'default' }} />
                                    {children}
                                  </GoogleMapsProvider>
                                </CompareProvider>
                              </CouponMarketplaceProvider>
                            </MobileMenuProvider>
                          </MiniCartProvider>
                        </DineInMiniCartProvider>
                      </OrderMethodModalProvider>
                    </ModalProvider>
                  </AlertProvider>
                </MenuProvider>
              </CommerceChannelProvider>
            </ThemeConfigProvider>
          </LocationProvider>
        </LinkProvider>
      </ThemeProvider>
    )
    : <Loading />
}
