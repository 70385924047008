import { createUseStyles } from 'react-jss'
import hexToRgba from '../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  productCardsPriceListContainer: {
    justifyContent: 'var(--ui-product-card-price-container-justify-content)',
  },
  productCardsPriceList: {
    justifyContent: 'var(--ui-product-card-price-list-justify-content)',
    fontSize: 'var(--ui-product-card-price-font-size)',
  },
  productCard: {
    flex: 1,
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    gap: '1rem',

    overflow: 'hidden',
    flexBasis: 'var(--ui-product-card-width)',
    padding: 'var(--ui-product-card-padding)',
    backgroundColor: 'var(--ui-product-card-background-color)',
    borderRadius: 'var(--ui-product-card-border-radius)',

    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.25,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  upperContent: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'var(--ui-product-card-flex-direction)',
  },
  figure: {
    position: 'relative',
    margin: 0,
    width: '100%',
    flexShrink: 0,
    flexBasis: 'var(--ui-product-card-thumbnail-width)',
    maxWidth: 'var(--ui-product-card-thumbnail-max-width)',
  },
  image: {
    height: 0,
    paddingBottom: 'var(--ui-product-card-image-padding-bottom)',
    position: 'relative',
    overflowY: 'hidden',
    backgroundColor: '#eee',
    borderRadius: 'var(--ui-product-card-image-border-radius)',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'var(--ui-product-card-content-align-items)',
    padding: 'var(--ui-product-card-content-padding)',
  },
  text: {
    height: '1.7rem',
    marginBottom: '1rem',
    backgroundColor: '#eee',
    borderRadius: '1rem',
    display: 'inline-block',
  },
  title: {
    extend: 'text',
    width: '90%',
  },
  variant: {
    extend: 'text',
    width: '50%',
  },
  price: {
    extend: 'text',
    width: '40%',
  },
}))

export default useStyles
