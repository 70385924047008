import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { ThemeProvider } from 'react-jss'
import themeConfigStyleService from '../../services/theme-config-style-service'
import theme from '../../theme'
import getThemeConfigStylesRegistrationList from './theme-config-styles-registration-list'

export default function ThemeProviderController({
  children,
  theme: themeConfig,
}) {
  const mergedTheme = useMemo(() => (
    _.merge(theme, themeConfig)
  ), [themeConfig])

  useEffect(() => {
    _.forEach(
      getThemeConfigStylesRegistrationList(mergedTheme),
      themeConfigStyleService.register,
    )
  }, [mergedTheme])
  return <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
}
