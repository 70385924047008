/* eslint-disable semi-style */
/* eslint-disable no-extra-semi */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './order-method-style'
import ArrowDownIconInline from '../../assets/icons/icon_dropdown_arrow.inline.svg'

export default function OrderMethodView({
  className,
  rowClassName,
  onClick,
  orderMethod,
  orderMethodValue,
  selectableOrderMethod,
}) {
  const { t } = useTranslation()
  const styles = useStyles()

  const showLabel = _.size(selectableOrderMethod) > 1

  if (_.isEmpty(selectableOrderMethod)) return null

  if (
    !showLabel
    && _.isEmpty(orderMethodValue)
    && _.get(orderMethod, 'code', '///') === _.get(_.first(selectableOrderMethod), 'code', '===')
  ) return null

  return (
    <button
      type="button"
      onClick={onClick}
      className={combineClassNames([styles.orderMethod, className])}
    >
      <span
        className={combineClassNames([styles.row, rowClassName])}
      >
        <span
          className={styles.leftContent}
        >
          {
            showLabel && _.isEmpty(_.get(orderMethod, 'code'))
              ? (
                t('ui.orderMethodModal.title')
              )
              : (
                <>
                  {
                    showLabel && (
                      t('ui.orderMethod.label', {
                        type: t('ui.orderMethodModal.options', { context: _.get(orderMethod, 'code') }),
                      })
                    )
                  }
                  {
                    showLabel && !_.isEmpty(orderMethodValue) && (
                      t('ui.orderMethod.separator')
                    )
                  }
                  {
                    !_.isEmpty(orderMethodValue) && (
                      <em
                        className={styles.orderMethodValue}
                      >
                        {orderMethodValue}
                      </em>
                    )
                  }
                </>
              )
          }
        </span>
        <ArrowDownIconInline className={styles.orderMethodIcon} />
      </span>
    </button>
  )
}
