/* eslint-disable no-underscore-dangle */
import _ from 'lodash'
import getBatchStyleFromTheme from '../helpers/get-batch-style-from-theme'

let _registry = []

// Register css variables
function register(item) {
  const {
    styleProperty,
  } = item
  if (_.findIndex(_registry, { styleProperty }) === -1) {
    _registry = [
      ..._registry,
      item,
    ]
  }
}

function getStyles({
  overrides = [],
  theme,
  isRoot,
}) {
  if (_.isEmpty(overrides)) {
    return getBatchStyleFromTheme({
      theme,
      isRoot,
      styles: _registry,
    })
  }
  const overrideStyles = _.map(overrides, (item) => {
    const {
      styleProperty,
    } = item
    const registeredStyle = _.find(_registry, { styleProperty })
    if (_.isUndefined(registeredStyle)) {
      register(item)
    }
    const result = {
      ..._.omit(registeredStyle, 'defaultValue'),
      ...item,
    }
    return result
  })
  return getBatchStyleFromTheme({
    theme,
    isRoot,
    styles: overrideStyles,
  })
}

export default {
  // cleanup,
  // find,
  // refresh,
  register,
  getStyles,
}
