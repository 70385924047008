import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  label: {
    marginLeft: '3.8rem',
    fontSize: '1.4rem',
    lineHeight: 1.43,
    margin: 0,
  },
  checkbox: {
    position: 'absolute',
    opacity: 0,
    '&:checked + $front:after': {
      display: 'block',
    },
  },
  wrapper: {
    display: 'flex',
    // background: theme.colors.background,
    maxHeight: '3rem',
    margin: '2.6rem 0',
  },
  wrapperDisabled: {
    opacity: 0.4,
  },
  front: {
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      width: '2rem',
      height: '2rem',
      border: `1px solid ${theme.colors.primary}`,
    },
    '&:after': {
      content: '""',
      display: 'none',
      top: '50%',
      left: '0.4rem',
      transform: 'translateY(-50%)',
      position: 'absolute',
      width: '1.2rem',
      height: '1.2rem',
      background: theme.colors.secondary,
    },
  },
}))

export default useStyles
