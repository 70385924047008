/* eslint-disable no-param-reassign */
import _ from 'lodash'
import getStyleFromTheme from './get-style-from-theme'
import mergeThemeStyles from './merge-theme-styles'

/*
 ** combine theme styles
 */
export default function getBatchStyleFromTheme({
  styles = [],
  theme,
  isRoot = false,
}) {
  if (_.isEmpty(theme) || _.isEmpty(styles)) return {}

  const data = _.map(styles, (option) => getStyleFromTheme({
    ...option,
    theme,
    isRoot,
  }))

  const baseStyles = _.map(data, (style) => _.get(style, ':root') || _.pick(style, ['extend']))
  const orderMethodSpecifiedStyles = _.reject(
    _.map(data, (style) => _.pickBy(style, (val, key) => _.includes(key, 'data-order-method='))),
    _.isEmpty,
  )
  const mergedBaseStyles = mergeThemeStyles(...baseStyles)
  const combinedOrderMethodSpecifiedStyles = _.reduce(
    orderMethodSpecifiedStyles,
    (result, style) => {
      _.forEach(style, (val, key) => {
        result = {
          ...(result || {}),
          [key]: _.has(result, key)
            ? _.concat(_.get(result, key, []), val)
            : _.castArray(val),
        }
      })
      return result
    },
    {},
  )
  const mergedOrderMethodSpecifiedStyles = _.reduce(
    combinedOrderMethodSpecifiedStyles,
    (result, val, key) => ({
      ...(result || {}),
      [key]: mergeThemeStyles(..._.castArray(val)),
    }),
    {},
  )

  // return style
  return {
    ...(
      isRoot
        ? { ':root': mergedBaseStyles }
        : mergedBaseStyles
    ),
    ...mergedOrderMethodSpecifiedStyles,
  }
}
