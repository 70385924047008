import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({

  containerFluidSeach: {
    width: '100%',
    overflow: 'hidden',
    maxHeight: 0,
    visibility: 'hidden',
    transition: 'visibility 1ms ease-in-out 0.3s, max-height 0.3s ease-in-out',
    // display: 'none',
  },
  containerFluidSeachOpen: {
    // display: 'block',
    maxHeight: '7.6rem',
    visibility: 'visible',
    transition: 'max-height 0.3s ease-in-out, visibility 1ms ease-in-out',
  },
  containerSeach: {
    width: '100%',
    maxWidth: theme.mediaQueries.desktop,
    marginTop: '0.3rem',
    padding: 0,
    display: 'flex',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '0 2rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      margin: [[0, 'auto', '1.5rem']],
      padding: 0,
    },
  },
}))

export default useStyles
