import _ from 'lodash'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  placeholderDiv: {
    minHeight: 400,
  },
  row: {
    margin: '0 -1.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '1.5rem',
    minHeight: '1rem',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      paddingBottom: '1.5rem',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      margin: '0 -1.5rem',
    },
  },
  row50: {
    maxWidth: `${theme.mediaQueries.desktop}px`,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      margin: '0 auto',
    },
  },
  col: {
    padding: '0 1.5rem',
    flexBasis: 'auto',
    flexGrow: 1,
    minWidth: 0,
    maxWidth: '100%',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      padding: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flexBasis: '0',
      // paddingTop: '1.5rem',
      // paddingBottom: '1.5rem',
    },
    '& img': {
      transform: 'scale(1.01)',
      width: '100%',
      display: 'block',
      margin: 0,
    },
  },
  col50: {
    padding: '1.5rem',
    maxWidth: '100%',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      padding: '1.5rem',
      maxWidth: '50%',
      width: '50%',
    },
    '&:first-child': {
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        paddingLeft: '0',
      },
    },
    '&:last-child': {
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        paddingRight: '0',
      },
    },
  },

  banner: {
    margin: 0,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    '& img': {
      transform: 'scale(1)',
    },
  },
  wrapImage: {
    overflow: 'hidden',
  },
  imageBoxOneImage: {
    padding: 0,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      margin: '0 0 1.5rem',
    },
  },
  imageBoxThreeImage: {
    padding: 0,
    margin: 0,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      margin: '0 0 1.5rem',
      padding: '0 1.5rem',
    },
  },
  imageBoxFourImage: {
    padding: 0,
    margin: 0,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      margin: '0 0 1.5rem',
      padding: '0 1.5rem',
    },
  },
  buttonMoreStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      margin: 0,
      padding: 0,
    },
  },
  productGroupStyle: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginBottom: '2.5rem',
    },
  },
  productCardItem: {
    padding: '0 0.8rem',
    width: '50%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '0 1.5rem',
      width: '25%',
    },
  },
  productCards: {
    position: 'relative',
    margin: 0,
    // borderRadius: '0.8rem',
    overflow: 'hidden',
    flex: 0,
    flexBasis: 'var(--pages-products-item-width)',
    padding: 'var(--pages-products-item-padding)',
    backgroundColor: 'var(--pages-products-item-background-color)',
    borderRadius: 'var(--pages-products-item-border-radius)',
  },
  productCardsUpperContentClassName: {
    display: 'flex',
    flexDirection: 'var(--pages-products-item-flex-direction)',
  },
  productCardsThumbnail: {
    flexBasis: 'var(--pages-products-item-thumbnail-width)',
    maxWidth: 'var(--pages-products-item-thumbnail-max-width)',
  },
  productCardsThumbnailImage: {
    borderRadius: 'var(--pages-products-item-image-border-radius)',
  },
  productCardsFavouriteButton: {
  },
  productCardsContent: {
    textAlign: 'var(--pages-products-item-content-text-align)',
    padding: 'var(--pages-products-item-content-padding)',
  },
  productCardsPriceListContainer: {
    justifyContent: 'var(--pages-products-item-price-container-justify-content)',
  },
  productCardsPriceList: {
    justifyContent: 'var(--pages-products-item-price-list-justify-content)',
    fontSize: 'var(--pages-products-item-price-font-size)',
  },
  moreButton: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
  },
  moreButtonIcon: {
    marginLeft: '0.8rem',
  },
  text: {
    color: 'currentcolor',
  },
  title: {
    fontSize: '2.4rem',
    fontWeight: 600,
    margin: '1.6rem 0 3rem',
    color: 'currentcolor',
    lineHeight: '2.8rem',
    position: 'relative',
    width: 'auto',
    textAlign: 'left',
    textTransform: 'uppercase',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      fontSize: '3.6rem',
      lineHeight: 1.16,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      height: 3,
      width: 50,
      backgroundColor: theme.colors.secondary,
      left: 0,
      bottom: '-12px',
    },
  },
  richText: {
    color: theme.colors.text,
    textAlign: 'left',
    fontSize: 14,
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  link: {
    all: 'unset',
    cursor: 'pointer',
  },
  imageList: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '-3rem',
    marginLeft: 'calc(var(--ui-content-group-image-list-gap) / -2)',
    marginRight: 'calc(var(--ui-content-group-image-list-gap) / -2)',
  },
  imageListColumns: {
    '--ui-content-group-image-list-number-of-columns-override': (props) => {
      const numberOfColumn = _.get(props, 'numberOfColumnsMobile')
      return numberOfColumn ? _.toNumber(numberOfColumn) : 'var(--ui-content-group-image-list-number-of-columns)'
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      '--ui-content-group-image-list-number-of-columns-override': (props) => {
        const numberOfColumn = _.get(props, 'numberOfColumnsTablet')
        return numberOfColumn ? _.toNumber(numberOfColumn) : 'var(--ui-content-group-image-list-number-of-columns)'
      },
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      '--ui-content-group-image-list-number-of-columns-override': (props) => {
        const numberOfColumn = _.get(props, 'numberOfColumnsDesktop')
        return numberOfColumn ? _.toNumber(numberOfColumn) : 'var(--ui-content-group-image-list-number-of-columns)'
      },
    },
  },
  imageListItem: {
    '--ui-content-group-image-list-item-width': 'calc((100% * (1 / var(--ui-content-group-image-list-number-of-columns-override))) - var(--ui-content-group-image-list-gap))',
    margin: '0 calc(var(--ui-content-group-image-list-gap) / 2) var(--ui-content-group-image-list-gap)',
    flexBasis: 'var(--ui-content-group-image-list-item-width)',
    flexGrow: 0,
    position: 'relative',
  },
  imageListItemImage: {
    display: 'block',
    margin: 0,
  },
  imageListItemName: {
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    left: '1rem',
    right: '1rem',
    transform: 'translateY(-50%)',
    color: 'var(--ui-content-group-image-list-item-name-color)',
    textAlign: 'center',
    lineHeight: '1.2em',
    fontSize: `max(1.4rem, calc(min(8vw, ${theme.mediaQueries.desktop * 0.08}px) / var(--ui-content-group-image-list-number-of-columns-override)))`,
  },
}))

export default useStyles
