/* eslint-disable no-param-reassign */
import _ from 'lodash'
import React, { useState } from 'react'
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTheme } from 'react-jss'
import combineClassNames from '../../helpers/combineClassNames';
import keyExtractor from '../../helpers/key-extractor';
import ProductCardPlaceholder from '../product-card-placeholder'
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import useStyles from './horizontal-product-list-style'

const HorizontalProductListView = (props) => {
  const styles = useStyles()
  const {
    data = [],
    keyExtractor: _keyExtractor = keyExtractor,
    onBreakpoint = () => {},
    onEndReached,
    pagination,
    renderItem = () => null,
    renderPlaceholder,
    ...rest
  } = props
  const [currentSlidesPerGroup, setCurrentSlidesPerGroup] = useState(2)
  const theme = useTheme()
  const mediaQueries = _.get(theme, 'mediaQueries')

  const onSwiperBreakpointChange = (swiper, options) => {
    setCurrentSlidesPerGroup(_.get(options, 'slidesPerGroup', 2))
    if (_.isFunction(onBreakpoint)) {
      onBreakpoint(swiper, options)
    }
  }

  const renderSlide = (item, index) => (
    <SwiperSlide
      key={_keyExtractor(item, index)}
    >
      {renderItem({ item, index })}
    </SwiperSlide>
  )
  const renderPlaceholderSlide = (index) => (
    <SwiperSlide
      key={keyExtractor({ id: index }, index, 'id', 'placeholder')}
    >
      {
        _.isFunction(renderPlaceholder)
          ? renderPlaceholder(index)
          : <ProductCardPlaceholder />
      }
    </SwiperSlide>
  )
  const numOfPlaceholders = (currentSlidesPerGroup - (_.size(data) % currentSlidesPerGroup))
    || currentSlidesPerGroup

  const navigationActive = _.size(data) > currentSlidesPerGroup
  return (
    <Swiper
      // install Swiper modules
      {...rest}
      className={combineClassNames([styles.swiper, navigationActive && styles.swiperActive])}
      style={{
        '--swiper-navigation-size': '2.5rem',
        '--swiper-navigation-sides-offset': '0',
        '--swiper-navigation-top-offset': '40%',
        '--swiper-pagination-bottom': '0',
      }}
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={16}
      slidesPerView={2}
      slidesPerGroup={2}
      breakpoints={{
        [mediaQueries.mobile]: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 16,
        },
        [mediaQueries.tablet]: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 30,
        },
        [mediaQueries.tabletLandscape]: {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 30,
        },
      }}
      navigation
      pagination={{
        clickable: true,
        dynamicBullets: true,
      }}
      // onSwiper={(swiperEvent) => console.log('//// swiperEvent ////', swiperEvent)}
      onReachEnd={onEndReached}
      onBreakpoint={onSwiperBreakpointChange}
    >
      {
        _.map(data, renderSlide)
      }
      {
        !_.get(pagination, 'isLastPage', true)
        && _.times(numOfPlaceholders, renderPlaceholderSlide)
      }
    </Swiper>
  )
}

export default HorizontalProductListView
