import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import combineClassNames from '../../../helpers/combineClassNames'
import placeholderImage from '../../../assets/images/icon_image_placeholder.png'

const Image = (props) => {
  const {
    alt,
    containerClassName,
    containerNoImageClassName,
    src,
    ...rest
  } = props// states
  const [noImage, setNoImage] = useState(_.isEmpty(src))
  const theme = useTheme()
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  useEffect(() => {
    if (_.isEmpty(src) !== noImage) {
      setNoImage(_.isEmpty(src))
    }
  }, [noImage, src])
  return (
    <div
      className={
        combineClassNames([
          containerClassName,
          noImage && containerNoImageClassName,
        ])
      }
    >
      <img
        role="presentation"
        alt={alt}
        {...rest}
        src={noImage ? placeholder : src}
        onError={() => {
          setNoImage(true)
        }}
        data-no-image={noImage}
      />
    </div>
  )
}

export default Image
