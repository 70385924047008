/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../button'
import DropdownMenu from '../dropdown-menu/dropdown-menu-view'
import ArrowDownIconInline from '../../../assets/icons/icon_dropdown_arrow.inline.svg'
import CartIconInline from '../../../assets/icons/icon_cart.inline.svg'
import SearchIconInline from '../../../assets/icons/icon_search.inline.svg'
import AccountIconInline from '../../../assets/icons/icon_account.inline.svg'
import StoresIconInline from '../../../assets/icons/icon_store_locations.inline.svg'

import combineClassNames from '../../../helpers/combineClassNames'
import useI18n from '../../../hook/use-i18n'
import { useCouponMarketplace } from '../../../hook/use-coupon-marketplace'
import { useDineInMiniCart } from '../../../hook/use-dine-in-mini-cart'
import { useLocation } from '../../../hook/use-location'
import { useMiniCart } from '../../../hook/use-mini-cart'
import useOrderMethod from '../../../hook/use-order-method'
import { useLink } from '../../../hook/use-link'
import useStyles from './tools-menu-style'

const Item = (props) => {
  const {
    className,
    type,
    onLanguageChange,
    onMemberCodeOpen,
    onSearch,
  } = props
  const { t } = useTranslation()
  const { navigate } = useLink()
  const { location } = useLocation()
  const {
    onCouponMarketplaceOpen,
  } = useCouponMarketplace()
  const {
    orderMethod,
  } = useOrderMethod()
  const {
    totalItems: cartTotalItem,
    openMiniCart,
  } = (_.get(orderMethod, 'commerceType') === 'dineIn' ? useDineInMiniCart : useMiniCart)()
  const { availableLocales, currentLocale } = useI18n()
  const currentLocaleCode = _.get(currentLocale, 'path', '')
  const isLanguageEnabled = _.size(availableLocales) > 0
  const isDineInMenuMode = _.get(orderMethod, 'code') !== 'dineIn' && _.get(orderMethod, 'type') === 'dineIn'
  const isCartPage = _.get(location, 'page') === '/cart/'
  const cartButtonDisabled = cartTotalItem <= 0 || isDineInMenuMode || isCartPage
  const onStoreClick = () => {
    navigate('/store-locations/')
  }
  // style
  const styles = useStyles()

  if (type === 'language' && isLanguageEnabled) {
    return (
      <li
        className={combineClassNames([
          styles.item,
          styles.language,
          styles.languageMemuAvailable,
          className,
        ])}
      >
        {t('locale', { context: currentLocaleCode })}
        <ArrowDownIconInline className={styles.languageMemuAvailableIcon} />
        <div className={styles.languageList}>
          {
            _.map(availableLocales, (availableLocale) => (
              <button
                key={`language_item_${availableLocale}`}
                className={combineClassNames([
                  styles.languageListItem,
                  currentLocaleCode === availableLocale && styles.currentLanguage,
                ])}
                type="button"
                onClick={onLanguageChange.bind(this, availableLocale)}
                aria-label={t('locale', { context: availableLocale })}
              >
                {t('locale', { context: availableLocale })}
              </button>
            ))
          }
        </div>
      </li>
    )
  }
  if (type === 'account') {
    return (
      <li
        className={
          combineClassNames([
            styles.item,
            styles.dropdownMenu,
            styles.account,
            className,
          ])
        }
      >
        <Button
          inline
          className={styles.iconButton}
          iconInline
          iconImage={AccountIconInline}
          iconClassName={styles.icon}
          aria-label="User Account"
        />
        <DropdownMenu
          stylesDropdown={
            combineClassNames([
              styles.containerDropdown,
              styles.accountDropdown,
            ])
          }
          onCouponMarketplaceOpen={onCouponMarketplaceOpen}
          onMemberCodeOpen={onMemberCodeOpen}
          // qrCode={qrCode}
        />
      </li>
    )
  }
  if (type === 'cart') {
    return (
      <li
        className={
          combineClassNames([
            styles.item,
            styles.cart,
            className,
          ])
        }
      >
        <Button
          aria-label="Cart"
          inline
          className={combineClassNames([
            styles.iconButton,
            styles.cartIconButton,
            cartButtonDisabled && styles.iconButtonDisabled,
            cartButtonDisabled && styles.CartIconButtonDisabled,
          ])}
          disabled={cartButtonDisabled}
          onClick={openMiniCart}
          iconInline
          iconImage={CartIconInline}
          iconClassName={
            combineClassNames([
              styles.icon,
              styles.cartIcon,
              cartButtonDisabled && styles.iconDisabled,
              cartButtonDisabled && styles.cartIconDisabled,
            ])
          }
          data-count={cartTotalItem >= 100 ? '99+' : cartTotalItem}
        />
      </li>
    )
  }
  if (type === 'search') {
    return (
      <li
        className={
          combineClassNames([
            styles.item,
            styles.search,
            className,
          ])
        }
      >
        <Button
          inline
          className={styles.iconButton}
          onClick={onSearch}
          iconInline
          iconImage={SearchIconInline}
          iconClassName={styles.icon}
          aria-label="Search"
        />
      </li>
    )
  }
  if (type === 'stores') {
    return (
      <li
        className={
          combineClassNames([
            styles.item,
            styles.stores,
            className,
          ])
        }
      >
        <Button
          inline
          className={styles.iconButton}
          onClick={onStoreClick}
          iconInline
          iconImage={StoresIconInline}
          iconClassName={styles.icon}
          aria-label="Stores"
          title={t('screens.storeLocations.seo.title')}
        />
      </li>
    )
  }
  return null
}

const ToolsMenuView = (props) => {
  const {
    className,
    itemClassName,
    ...itemProps
  } = props
  const styles = useStyles()
  const list = ['language', 'account', 'cart', 'search', 'stores']
  return (
    <ul className={combineClassNames([styles.toolsMenu, className])}>
      {
        _.map(list, (item) => {
          const type = _.get(item, 'key', item)
          return (
            <Item
              key={`tools_menu_item_${type}`}
              type={type}
              className={itemClassName}
              {...itemProps}
            />
          )
        })
      }
    </ul>
  )
}

export default ToolsMenuView
