import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  productCard: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    gap: '1rem',

    margin: 0,
    overflow: 'hidden',
    flex: 0,
    padding: 'var(--ui-product-card-padding)',
    backgroundColor: 'var(--ui-product-card-background-color)',
    borderRadius: 'var(--ui-product-card-border-radius)',
  },
  productCardCompare: {
    '&::after': {
      content: '""',
      display: 'block',
      width: 0,
      height: '3rem',
    },
  },
  upperContent: {
    gap: '1rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'var(--ui-product-card-flex-direction)',
  },
  link: {
    textDecoration: 'none',
    flexGrow: 1,
  },
  figure: {
    position: 'relative',
    margin: 0,
    width: '100%',
    flexShrink: 0,

    flexBasis: 'var(--ui-product-card-thumbnail-width)',
    maxWidth: 'var(--ui-product-card-thumbnail-max-width)',
  },
  favouriteButton: {
    position: 'absolute',
    zIndex: 1,
    top: '0.6rem',
    right: '0.6rem',
  },
  icon: {
    position: 'absolute',
    right: '1.3rem',
    top: '1.3rem',
    cursor: 'pointer',
    maxWidth: '1.8rem',
  },
  image: {
    aspectRatio: 'var(--ui-product-card-image-aspect-ratio)',
    borderRadius: 'var(--ui-product-card-image-border-radius)',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center center',
      width: '100%',
      height: '100%',
      margin: 0,
    },
  },
  noImage: {
    backgroundColor: '#f1f1f1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '32%',
      height: 'auto',
    },
  },
  content: {
    color: theme.colors.text,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'var(--ui-product-card-content-text-align)',
    padding: 'var(--ui-product-card-content-padding)',
  },
  contentText: {
    marginBottom: '0.2rem',
    fontSize: '1.4rem',
    lineHeight: '1.42em',
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  details: {
    // display: 'none',
    display: 'var(--ui-product-card-content-details-display)',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    color: theme.colors.bgMediumDark,
  },
  customLabelsContainer: {
    minHeight: '2rem',
    marginBottom: '0.8rem',
    marginTop: '1rem',
    '&:empty': {
      display: 'none',
    },
  },
  customLabels: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'var(--ui-product-card-custom-labels-justify-content)',
    alignItems: 'baseline',
  },
  colorSelect: {
    color: theme.colors.disabled,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'var(--ui-product-card-price-container-justify-content)',
  },
  price: {
    fontSize: 'var(--ui-product-card-price-font-size)',
    padding: '0.5rem 0',
    justifyContent: 'var(--ui-product-card-price-list-justify-content)',
  },
  checkbox: {
    justifyContent: 'center',
    margin: '0',
    position: 'absolute',
    bottom: '1rem',
    left: 0,
    width: '100%',
    '& div:nth-child(3)': {
      marginLeft: '2.2rem',
    },
  },
  front: {
    '&::before': {
      width: 16,
      height: 16,
    },
    '&::after': {
      left: '0.2rem',
    },
  },
  textCheckbox: {
    textTransform: 'uppercase',
  },
}))

export default useStyles
