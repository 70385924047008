/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import {
  useSystemSettings,
} from 'react-omnitech-api'
import { useAnalytics, useLink, useThemeConfig } from '../../hook'
import { getProductUrl } from '../../helpers'
import ProductCardView from './product-card-view'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductCardController = (props) => {
  const {
    productId,
    title,
    colorName,
    colorOptionId,
    url,
    distinct,
    sku,
    code,
    onClickTrackEvent,
    index,
    list,
    ...rest
  } = props

  // prepare hook
  const { navigate } = useLink()
  const { getSystemSetting } = useSystemSettings()
  const { getConfig } = useThemeConfig()
  const { getProductParams } = useAnalytics()
  const [selectedColorOption, setSelectedColorOption] = useState({})
  const [updatedFavourites, setUpdatedFavourites] = useState({})

  // local variable
  const favouritesEnabled = !getSystemSetting('hide_favourites', false)
  const enablePlpSwatch = getConfig('config.enablePlpSwatch', false)
  const productDetailUrl = useMemo(() => getProductUrl({
    colorOptionId: _.get(selectedColorOption, 'colorOptionId', colorOptionId),
    productId,
    url,
  }), [selectedColorOption, productId, url])
  const showSwatches = useMemo(() => (
    _.isEqual(distinct, 'p')
    && enablePlpSwatch
    && !_.get(sku, 'product.colorOptionVariantType.hideVariant', false)
    && _.get(sku, 'product.colorOptionCount', 0) > 1
  ), [sku, distinct, enablePlpSwatch])
  const maxNoOfCustomLabel = getConfig('config.ui.productCard.maxNoOfCustomLabel', 1)

  function handleClick() {
    // when browser support javascript, use navigate function instead
    // then can handle external tracking in onClick callback
    onClickTrackEvent('customerProductClick', {
      ...getProductParams(sku),
      name: title,
      // category: _.get(sku, 'product.categories[0].code', ''), // product category
      // brand: _.get(sku, 'product.brand[0].code', ''), // product brand
      variant: colorName, // product color
      position: index,
      list: list || 'Search Results',
    })
  }

  const onSwatchItemClick = ({ colorOption, sku: _sku }) => {
    // change colorOptionId
    const selectedColorOptionId = _.get(colorOption, 'id')
    const favourite = _.get(updatedFavourites, selectedColorOptionId, _.get(colorOption, 'favourite'))
    setSelectedColorOption({
      colorOptionId: selectedColorOptionId,
      colorName: _.get(colorOption, 'name'),
      sellPrice: _.get(colorOption, 'sellPrice'),
      originalPrice: _.get(colorOption, 'originalPrice'),
      activeCustomLabels: _.get(colorOption, 'activeCustomLabels'),
      favourite,
      imageUrl: _.get(colorOption, 'images.0.versions.webLarge')
                || _.get(colorOption, 'defaultImage.versions.webLarge'),
      firstSkuOfSelectedColorOption: _sku,
      ...(
        _.isEqual(distinct, 'p') ? {} : { stockLevel: _.get(colorOption, 'stockLevel') }
      ),
    })
  }
  const onSwatchMoreClick = (e) => {
    // change colorOptionId
    handleClick(e)
    navigate(productDetailUrl)
  }

  const onFavouriteChange = ({ favourite }) => {
    const selectedColorOptionId = _.get(selectedColorOption, 'colorOptionId', colorOptionId)
    setUpdatedFavourites((previousUpdatedFavourites) => ({
      ...previousUpdatedFavourites,
      [selectedColorOptionId]: favourite,
    }))
    setSelectedColorOption((previousSelectedColorOption) => ({
      ...previousSelectedColorOption,
      colorOptionId: selectedColorOptionId,
      favourite,
    }))
  }

  const viewProps = {
    colorOptionId,
    favouritesEnabled,
    maxNoOfCustomLabel,
    onClick: handleClick,
    onFavouriteChange,
    onSwatchItemClick,
    onSwatchMoreClick,
    title,
    colorName,
    sku,
    showSwatches,
    productDetailUrl,
    ...rest,
    ...selectedColorOption,
  }

  return (
    <ProductCardView {...viewProps} />
  )
}

export default ProductCardController
