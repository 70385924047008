/* eslint-disable no-param-reassign */
import _ from 'lodash'
import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    margin: '0 auto',
    paddingTop: '1px',
    marginTop: '-1px',
    backgroundColor: 'var(--ui-header-background-color)',
    borderBottomWidth: 'var(--ui-header-border-bottom-width)',
    borderBottomStyle: 'var(--ui-header-border-bottom-style)',
    borderBottomColor: 'var(--ui-header-border-bottom-color)',
  },
  container: {
    display: 'flex',
    gap: '1rem 1.6rem',
    flexWrap: 'wrap',
    position: 'relative',
    // justifyContent: 'center',
    alignItems: 'stretch',
    margin: [['0', 'auto']],
    padding: [['0', '1.6rem']],
    minHeight: '5rem',
    marginTop: 'var(--ui-header-top-spacing)',
    marginBottom: 'var(--ui-header-bottom-spacing)',
    justifyContent: 'var(--ui-header-logo-container-justify-content)',
  },
  topBar: {
    marginTop: '-0.5rem',
    marginBottom: '0.5rem',
    backgroundColor: _.get(theme, 'config.ui.header.topBar.backgroundColor', '#000000'),
    color: _.get(theme, 'config.ui.header.topBar.textColor', '#ffffff'),
    textAlign: 'center',
    padding: '0 1rem',
  },
  topBarContent: {
    color: 'currentColor',
    lineHeight: '1.2em',
    padding: '0.4em 0',
  },
  logo: {
    width: '50%',
    minHeight: '5rem',
    margin: 0,
    padding: [['0.2rem', 0]],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: 0,
    },
    maxWidth: 'var(--ui-header-logo-width)',
  },
  logoImage: {
    display: 'block',
    width: '100%',
    margin: 0,
  },
  boxLogin: {
    right: '2rem',
    top: 'var(--ui-header-tools-menu-top)',
    bottom: 'var(--ui-header-tools-menu-bottom)',
    position: 'var(--ui-header-tools-menu-position, absolute)',
  },
  boxLoginList: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-0.9rem',
    marginBottom: 0,
    height: '100%',
  },
  boxLoginItem: {
    display: 'none',
    margin: '0 0 0 1.7rem',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    textDecoration: 'none',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      display: 'inline-block',
    },
    '& a': {
      color: 'currentColor',
      textDecoration: 'none',
    },
    '& button, & img': {
      outline: 'none',
    },
  },
  cart: {
    display: 'inline-block',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      marginLeft: '0.7rem',
    },
  },
  cartIconButton: {
    background: 'transparent',
    position: 'relative',
    border: 0,
    padding: '1.2rem 2.2rem',
    lineHeight: 1,
    transform: 'translateY(0.5rem)',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      padding: '0.5rem 1rem',
      marginRight: '-1rem',
      transform: 'none',
    },
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: 'attr(data-count)',
      backgroundColor: Color(theme.colors.secondary).alpha(0.8).string(),
      borderRadius: '0.9rem',
      padding: '0.2rem 0.5rem',
      top: '0.3rem',
      left: '2.9rem',
      minWidth: '1.6rem',
      zIndex: 10,
      fontSize: '1rem',
      lineHeight: '1.2rem',
      fontWeight: '600',
      color: Color(theme.colors.primary).alpha(0.8).string(),
      [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
        top: '-0.2rem',
        left: '1.7rem',
      },
    },
  },
  cartIconButtonDisabled: {
    '&:after': {
      display: 'none',
    },
  },
  cartIcon: {
    marginLeft: 0,
    opacity: 0.8,
    width: '1.6rem',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      transform: 'translate(0.1rem, 0.15rem) scale(1.25)',
    },
  },
  cartIconDisabled: {
    opacity: 0.4,
  },
  languageList: {
    display: 'none',
    position: 'absolute',
    zIndex: 100,
    top: '100%',
    left: 0,
    border: '0.2rem solid currentColor',
    width: '13rem',
    backgroundColor: theme.colors.background,
  },
  languageListItem: {
    appearance: 'none',
    border: 'none',
    background: 'transparent',
    display: 'block',
    width: '100%',
    minHeight: '4.8rem',
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: '1.6rem',
    color: theme.colors.text,
    '&:hover': {
      backgroundColor: theme.colors.bgLight,
    },
  },
  languageMemuAvailable: {
    paddingRight: '2rem',
    position: 'relative',
    '&:hover $languageList': {
      display: 'block',
    },
  },
  languageMemuAvailableIcon: {
    display: 'block',
    width: '1.2rem',
    height: '0.8rem',
    position: 'absolute',
    right: '0.2rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
  },
  containerDropdown: {
    display: 'none',
    position: 'absolute',
    border: '2px solid currentColor',
    backgroundColor: theme.colors.background,
    listStyle: 'none',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2,
    '& ul': {
      listStyle: 'none',
      margin: 0,
    },
  },
  dropdownMenu: {
    '&:hover $containerDropdown': {
      display: 'block',
    },
  },
  burgerMenu: {
    // position: 'absolute',
    left: 12,
    margin: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.config.headerBackgroundColor,
    width: '4rem',
    display: 'var(--ui-header-burger-menu-display)',
    position: 'var(--ui-header-burger-menu-position)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  burgerMenuLabel: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    display: 'var(--ui-header-burger-menu-label-display)',
  },
  menuIcon: {
    width: '1.6rem',
    // transform: 'translateY(0.5rem)',
    color: theme.config.headerColorText,
    marginLeft: 0,
    marginTop: '0.2rem',
    marginBottom: '0.2rem',
  },
  search: {
    cursor: 'pointer',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      marginLeft: '0.7rem',
    },
  },
  searchIconButton: {
    padding: '0.65rem 1rem 0.5rem 0.9rem',
    marginRight: '-1rem',
    lineHeight: 1,
    backgroundColor: 'transparent',
  },
  searchIcon: {
    width: '1.6rem',
    color: theme.config.headerColorText
      ? theme.config.headerColorText
      : theme.colors.primary,
  },
  searchBar: {
    // display: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      // margin: '0 auto 15px',
      maxWidth: theme.mediaQueries.large,
      // display: 'block',
    },
    transform: 'translateY(calc(min(var(--ui-header-bottom-spacing), -1 * var(--ui-header-bottom-spacing)) * 0.8))',
  },
  megaMenu: {
    display: 'var(--ui-header-mega-menu-display)',
    marginTop: 'var(--ui-header-mega-menu-top-spacing)',
    marginBottom: 'var(--ui-header-mega-menu-bottom-spacing)',
  },
  orderMethod: {
  },
  orderMethodBelowLogo: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    display: 'var(--ui-header-order-method-below-logo-display)',
    borderBottomWidth: 'var(--ui-header-order-method-border-width)',
    borderBottomStyle: 'var(--ui-header-order-method-border-style)',
    borderBottomColor: 'var(--ui-header-order-method-border-color)',
    borderTopWidth: 'var(--ui-header-order-method-border-width)',
    borderTopStyle: 'var(--ui-header-order-method-border-style)',
    borderTopColor: 'var(--ui-header-order-method-border-color)',
    marginBottom: 'min(var(--ui-header-order-method-border-width), -1 * var(--ui-header-order-method-border-width))',
    marginTop: 'min(var(--ui-header-order-method-border-width), -1 * var(--ui-header-order-method-border-width))',
  },
  orderMethodBelowLogoRow: {
    justifyContent: 'var(--ui-header-order-method-below-logo-row-justify-content)',
  },
  orderMethodNearLogo: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'var(--ui-header-order-method-near-logo-display)',
  },
}))

export default useStyles
