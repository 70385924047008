/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import _ from 'lodash'
import URI from 'urijs'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useBrands,
  useDepartments,
  useCategories,
  useSystemSettings,
} from 'react-omnitech-api'
import { getLinkTargeyByID, isUrl } from '../../helpers'
import { useAnalytics } from '../../hook'
import Templates from './templates'
/**
 * ContentGroupTemplate
 *
 * the component will be used for both content group and content group line.
 *
 * supported template:
 * Banner
 * Banner2Col
 * Banner3Col
 * Banner4Col
 * Carousel
 * ContentGroup
 * ProductGroup
 * List
 * Menu
 * Text
 *
 * @param {*} props
 */
function ContentGroupTemplate(props) {
  const {
    template,
    contentGroup = {},
  } = props
  const { brands } = useBrands()
  const { departments } = useDepartments()
  const { categories } = useCategories()
  const { getSystemSetting } = useSystemSettings()
  const { trackEvent } = useAnalytics()
  const { contentGroupLines } = contentGroup
  const contentGroupLinesWithUrl = useMemo(() => _.map(contentGroupLines, (contentGroupLine) => {
    const {
      linkTarget, linkTargetType, url, linkTargetId,
    } = contentGroupLine
    let link = new URI(url || '/')
    switch (linkTargetType) {
      case 'Brand':
        const brandCodeEq = getLinkTargeyByID('Brand', linkTargetId, { brands })
        link.path('/products/')
        link.addSearch({
          brandNames: brandCodeEq,
          brandCodeEq,
        })
        break

      case 'Department':
        const departmentCodeEq = getLinkTargeyByID('Department', linkTargetId, { departments })
        link.path('/products/')
        link.addSearch({ departmentCodeEq })
        break

      case 'Category':
        const categoryGroup = getLinkTargeyByID('Category', linkTargetId, { categories, departments })
        link.path('/products/')
        link.addSearch({
          categoryCodeEq: _.get(categoryGroup, 'category'),
          departmentCodeEq: _.get(categoryGroup, 'departament', ''),
        })
        break
        // TODO waiting Api team solution, now we can not get product only with ID colorOption
        // if not solution by API team, delete 29/10/00
      case 'ColorOption':
        link.path('/products/')
        link.addSearch({ colorOptionIds: linkTargetId })
        break

      case 'ProductGroup':
        link.path('/products/')
        link.addSearch({ productGroupId: linkTargetId })
        link = link.readable()
        break

      case 'Product':
        link.path(`/product/${linkTargetId}`)
        break

      case 'LabelMaster':
        link.path('/products/')
        link.addSearch({ activeCustomLabelIds: linkTargetId })
        break

      case 'Article':
        link.path(`/articles/${linkTargetId}`)
        break

      case 'Store':
        link.path(`/stores/${linkTargetId}`)
        break

      case 'Page':
        link.path(`/pages/${linkTargetId}`)
        break

      default:
        // check and using url directly if it is a full path
        if (isUrl(url)) {
          link = url
        } else if (_.isEmpty(url)) {
          link = ''
        }
        break;
    }
    const linkResult = _.isString(link) ? link : `${link.path()}${link.search()}`

    return { ...contentGroupLine, url: linkResult }
  }), [brands, categories, contentGroupLines, departments])

  // map the specific template component
  const TemplateComponent = useMemo(() => Templates[template] || null, [template])
  const onClickTrackEvent = (templateName, label) => {
    trackEvent('customerButtonClick', {},
      {
        gaSendEventCategory: templateName,
        gaSendEventLabel: label,
      })
  }
  let componentProps = {
    ...props,
    onClickTrackEvent,
    contentGroup: {
      ...contentGroup,
      contentGroupLines: contentGroupLinesWithUrl,
    },
  }
  // if no content group lines then get richText from contentGroup.body
  if (_.isEmpty(contentGroupLines)) {
    componentProps = {
      ...componentProps,
      configOptions: {
        display: 'richText',
        ..._.get(props, 'configOptions', {}),
      },
    }
  }

  return TemplateComponent ? <TemplateComponent {...componentProps} /> : null
}

ContentGroupTemplate.propTypes = {
  template: PropTypes.any.isRequired,
}

export default ContentGroupTemplate
