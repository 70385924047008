/* eslint-disable no-param-reassign */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'react-omnitech-api'
import HorizontalProductList from '../horizontal-product-list'
import ProductCard from '../product-card'
import useCustomerRankSettings from '../../hook/use-customer-rank-settings'
import useStyles from './recommendation-products-style'

const RecommendationProductsView = (props) => {
  const {
    className,
    ListHeaderComponent,
    onAfterInit,
    onBreakpoint,
    onClickTrackEvent,
    onNextLoad,
    onPageChange,
    pagination,
    items,
    siblings,
    siblingsLoading,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  const {
    loyaltyInformation,
  } = useUser()
  const { default: defaultRank } = useCustomerRankSettings()
  const currentRank = _.get(loyaltyInformation, 'currentCustomerRank.code', defaultRank)

  const renderProduct = ({ item, index }) => {
    const siblingsProductCodes = _.get(item, 'siblingsProductCodes', [])
    const thisSiblings = _.filter(siblings, (sibling) => (
      _.includes(siblingsProductCodes, _.get(sibling, 'product.code', '////'))
    ))
    const thisSiblingsLoading = !_.isEmpty(siblingsProductCodes)
      && _.isEmpty(thisSiblings)
      && siblingsLoading
    const minimumBundlePrice = _.get(item, 'sku.meta.priceDisplayMode') === 'minimum_bundle_price'
      ? _.get(item, 'sku.meta.minimumBundlePrice', null)
      : null
    const haveSiblings = !_.isEmpty(thisSiblings)
    const priceSuffix = (haveSiblings || _.isNumber(minimumBundlePrice)) && t('screens.product.price.up')
    const lowestSellPriceSibling = _.reduce(thisSiblings, (result, sibling) => {
      if (
        _.toNumber(_.get(result, 'sellPrice', 0))
        > _.toNumber(_.get(sibling, 'sellPrice', 0))
      ) {
        result = sibling
      }
      return result
    }, _.first(thisSiblings))
    let displaySellPrice = _.get(item, 'sellPrice')
    if (haveSiblings) displaySellPrice = _.get(lowestSellPriceSibling, 'sellPrice')
    if (_.isNumber(minimumBundlePrice)) displaySellPrice = minimumBundlePrice
    let displayOriginalPrice = _.get(item, 'originalPrice')
    if (haveSiblings) displayOriginalPrice = _.get(lowestSellPriceSibling, 'originalPrice')
    if (_.isNumber(minimumBundlePrice)) displayOriginalPrice = null
    let displayMemberPrice = _.get(item, 'memberPrice')
    if (haveSiblings) displayMemberPrice = _.get(lowestSellPriceSibling, `meta.memberPrice.${currentRank}`)
    if (_.isNumber(minimumBundlePrice)) displayMemberPrice = null
    return (
      <ProductCard
        {...item}
        sellPrice={displaySellPrice}
        originalPrice={displayOriginalPrice}
        memberPrice={displayMemberPrice}
        priceSuffix={priceSuffix}
        priceLoading={thisSiblingsLoading}
        onClickTrackEvent={onClickTrackEvent}
        index={index + 1}
      />
    )
  }
  return (
    <section className={className}>
      <header className={styles.header}>
        <div className={styles.headerComponent}>
          {ListHeaderComponent}
        </div>
      </header>
      <HorizontalProductList
        data={items}
        renderItem={renderProduct}
        pagination={pagination}
        onAfterInit={onAfterInit}
        onBreakpoint={onBreakpoint}
        onEndReached={onNextLoad}
        onSlideChange={onPageChange}
      />
    </section>
  )
}

export default RecommendationProductsView
