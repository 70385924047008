/* eslint-disable no-param-reassign */
import _ from 'lodash'

/*
 ** Turn style value from theme to style with media query support
 */
export default function mergeThemeStyles(...styles) {
  if (_.isEmpty(styles)) return {}
  const mergeExtends = (ext1, ext2) => {
    const all = _.concat(ext1, ext2)
    const data = _.reduce(
      all,
      (result, style) => {
        _.each(style, (value, key) => {
          if (_.includes(key, '@media')) {
            _.set(result, 'mediaQueries', {
              ..._.get(result, 'mediaQueries', {}),
              [key]: {
                ..._.get(result, `mediaQueries.${key}`, {}),
                ...value,
              },
            })
          } else {
            _.set(result, 'others', {
              ..._.get(result, 'others', {}),
              [key]: value,
            })
          }
        })
        return result
      },
      {
        mediaQueries: {},
        others: {},
      },
    )
    return [
      _.get(data, 'others', {}),
      ..._.sortBy(
        _.map(
          _.get(data, 'mediaQueries', {}),
          (value, key) => ({
            [key]: value,
          }),
        ),
        (style) => {
          // sort by media queries width
          const mediaQueryKey = _.first(_.keys(style))
          const regex = /@media \(min-width: (?<width>\d+)px\)/i;
          const width = _.toNumber(_.get(regex.exec(mediaQueryKey), 'groups.width', '0'))
          return width
        },
      ),
    ]
  }
  return _.reduce(styles, (result, style = {}) => {
    const {
      extend = [],
      ...rest
    } = style
    const prevExtend = _.get(result, 'extend', [])
    result = {
      ...(result || {}),
      ...rest,
      extend: mergeExtends(prevExtend, extend),
    }
    return result
  }, {})
}
