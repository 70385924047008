import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    backgroundColor: theme.colors.background,
    borderBottom: `2px solid ${theme.colors.primary}`,
    width: '100%',
  },
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [['1rem', 0]],
    margin: [[0, 'auto']],
    minHeight: 48,
    '& h1': {
      margin: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.tabletLandscape}px)`]: {
      '& h1': {
        border: 'none',
      },
    },
  },
  logo: {
    width: '50%',
    margin: 0,
    padding: [['0.2rem', 0]],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: 0,
    },
    maxWidth: 'var(--ui-header-checkout-logo-width)',
  },
  logoImage: {
    display: 'block',
    width: '100%',
    margin: 0,
  },
}))

export default useStyles
