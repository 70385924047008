/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    'body.modal-open': {
      overflow: 'hidden',
      height: '100vh',
      width: '100%',
      position: 'fixed',
    },
  },
  container: {
    display: 'flex',
    position: 'fixed',
    opacity: 1,
    top: 0,
    zIndex: '1000',
    left: '-100vw',
    height: '-webkit-fill-available',
    fallbacks: {
      height: '100vh',
    },
    width: '100vw',
    justifyContent: 'stretch',
    alignContent: 'stretch',
    transition: 'left 300ms ease',
  },
  containerOpen: {
    left: '0',
    transition: 'left 300ms ease',
  },
  menuMobile: {
    display: 'block',
    minWidth: 275,
    width: '85vw',
    maxWidth: 420,
    height: '-webkit-fill-available',
    fallbacks: {
      height: '100vh',
    },
    background: theme.colors.background,

  },
  closeMenuTriggerArea: {
    width: '15vw',
    flex: 1,
  },
  boxButtons: {
    display: 'var(--ui-sidebar-menu-account-buttons-display)',
    borderBottom: `1px solid ${theme.colors.bgMedium}`,
    '& a:first-child': {
      borderRight: `1px solid ${theme.colors.bgMedium}`,
    },
    '& a, & span': {
      width: '50%',
      display: 'block',
      padding: '1.6rem 0',
      margin: 0,
      textAlign: 'center',
      fontSize: '1.4rem',
      lineHeight: 1.14,
      textDecoration: 'none',
      color: theme.colors.primary,
      textTransform: 'capitalize',
    },

  },
  containerButtons: {
    flexDirection: 'column',
    width: '100%',
  },
  listMenuAuth: {
    listStyle: 'none',
    width: '100%',
    margin: 0,
    '& li': {
      height: '4,9rem',
      padding: '0 20px',
      borderBottom: `1px solid ${theme.colors.bgMedium}`,
      margin: 0,
    },
    '& li:first-child': {
      borderTop: `1px solid ${theme.colors.bgMedium}`,
    },
    '& li:last-child': {
      borderBottom: 'none',
    },
    '& li a:first-child': {
      borderRight: 'none',
    },
    '& li a, & li span': {
      width: '100%',
      textAlign: 'left',
    },
  },
  boxMenu: {
    height: 'calc(100vh - 55px)',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    paddingBottom: 200,
  },
  search: {
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: 'calc(100% - 4rem)',
      margin: '0 auto',
    },
    display: 'var(--ui-sidebar-menu-search-display)',
  },
}))

export default useStyles
