import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  breadcrumb: {
    padding: '0 1.5rem',
    margin: [[0, 0, 15]],
    listStyle: 'none',
    color: theme.colors.primary,
    textAlign: 'left',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: '0 2rem',
      margin: [[0, 0, 30]],
    },
  },
  item: {
    display: 'inline',
    fontSize: '1.6rem',
    fontWeight: 300,
    '& p': {
      display: 'inline-block',
    },
    '&+$item:before': {
      padding: 8,
      color: theme.colors.primary,
      content: `"${String.fromCharCode(62)}"`,
    },
    '& a': {
      color: theme.colors.disabled,
      textDecoration: 'none',
    },
  },
}))

export default useStyles
