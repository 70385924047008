/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import combineClassNames from '../../helpers/combineClassNames'
import useStyles from './product-card-style'

// TODO: base on PLP distinct on `p` or `pc`, the data structure will be different
// will need to use system setting to control it.
const ProductCardView = (props) => {
  const {
    className,
    contentClassName,
    upperContentClassName,
    thumbnailClassName,
    thumbnailImageClassName,
  } = props
  const styles = useStyles()

  return (
    <div className={combineClassNames([styles.productCard, className])}>
      <div className={combineClassNames([styles.upperContent, upperContentClassName])}>
        <div className={combineClassNames([styles.figure, thumbnailClassName])}>
          <div className={combineClassNames([styles.image, thumbnailImageClassName])} />
        </div>
        <div className={combineClassNames([styles.content, contentClassName])}>
          <div className={styles.title} />
          <div className={styles.variant} />
          <div className={styles.price} />
        </div>
      </div>
    </div>
  )
}

export default ProductCardView
