import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  image: {},
  placeholder: {
    backgroundColor: theme.colors.bgLight,
    padding: '0 30%',
  },
}))

export default useStyles
