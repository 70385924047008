/* eslint-disable import/no-cycle */
import Banner from './banner'
import Banner2Col from './banner-2-col'
import Banner3Col from './banner-3-col'
import Banner4Col from './banner-4-col'
import Carousel from './carousel'
import ContentGroup from './content-group'
import FacebookPageWidget from './facebook-page-widget'
import ImageList from './image-list'
import ProductGroup from './product-group'
import List from './list'
import Menu from './menu'
import Text from './text'

export default {
  Banner,
  Banner2Col,
  Banner3Col,
  Banner4Col,
  Carousel,
  ContentGroup,
  FacebookPageWidget,
  ImageList,
  ProductGroup,
  List,
  Menu,
  Text,
}
